import { Provider } from 'react-redux';

import store from './store';
import { RouterProvider } from './routes';

import './styles/index.global.scss';

function App() {
  return (
    <Provider store={store}>
      <RouterProvider />
    </Provider>
  );
}

export default App;
