import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BrowserStorageService, BrowserStorageKeys, MaintenanceValues } from 'services';

import { getFAQAnswers } from '../../api/auth';
import { Logo, MaintenanceIcon } from '../../assets/icons';
import { Button } from '../../components';
import { RoutesData } from '../../types';
import { authActions } from '../../store/authSlice';
import { useAppDispatch } from '../../hooks';

import styles from './Maintenance.module.scss';

const Maintenance = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tryAgain = async () => {
    setLoading(true);
    try {
      const response = await getFAQAnswers();
      if (response?.status === 200) {
        await dispatch(authActions.signOut());
        BrowserStorageService.set(BrowserStorageKeys.IsMaintenance, MaintenanceValues.Inactive);
        navigate(RoutesData.Login, { replace: true });
        window.location.reload();
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.wrapper__logo}>
          <Logo />
        </div>
        <div className={styles.wrapper__img}>
          <MaintenanceIcon />
        </div>
        <div className={styles.wrapper__title}>Under maintenance</div>
        <div className={styles.wrapper__text}>
          The service is currently under maintenance, please <br /> try again later.
        </div>
        <div className={styles.wrapper__btn_container}>
          <Button title='Try again' callback={tryAgain} disabled={loading} />
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
