import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { DashboardSliceState } from './types';

const selectDashboard = (state: RootState): DashboardSliceState => state.dashboard;

export const selectHistoricalCommissions = createSelector(
  selectDashboard,
  (dashboard) => dashboard.historicalCommissions,
);

export const selectOpenCommissions = createSelector(
  selectDashboard,
  (dashboard) => dashboard.openCommissions,
);

export const selectReinvestedTokens = createSelector(
  selectDashboard,
  (dashboard) => dashboard.reinvestedTokens,
);

export const selectReinvestedTokenLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard.reinvestedTokensLoading,
);

export const selectReinvestedTokensSum = createSelector(
  selectDashboard,
  (dashboard) => dashboard.reinvestedTokensSum,
);

export const selectReinvestedTokensSumLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard.reinvestedTokensSumLoading,
);

export const selectCommissionsLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard.commissionsLoading,
);

export const selectChartData = createSelector(selectDashboard, (dashboard) => dashboard.chartData);

export const selectNewsListData = createSelector(
  selectDashboard,
  (dashboard) => dashboard.newsList,
);
export const selectNewsData = createSelector(selectDashboard, (dashboard) => dashboard.news);

export const selectEventsList = createSelector(
  selectDashboard,
  (dashboard) => dashboard.eventsList,
);
export const selectEventData = createSelector(selectDashboard, (dashboard) => dashboard.event);
