import axios from 'axios';

import store from 'store';
import { authActions } from 'store/authSlice';
import { BrowserStorageService, BrowserStorageKeys, MaintenanceValues } from 'services';

const initialConfig = {
  baseURL: process.env.REACT_APP_API_URL,
};

const client = axios.create(initialConfig) as any;

client.interceptors.request.use((config: any) => {
  const token =
    BrowserStorageService.get(BrowserStorageKeys.AccessToken) ??
    BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

  if (token) {
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  }

  return config;
});

client.interceptors.response.use(
  (response: any) => {
    BrowserStorageService.set(BrowserStorageKeys.IsMaintenance, MaintenanceValues.Inactive);
    return response;
  },

  (error: any) => {
    if (error.response?.status === 503) {
      BrowserStorageService.set(BrowserStorageKeys.IsMaintenance, MaintenanceValues.Active);
    } else {
      BrowserStorageService.set(BrowserStorageKeys.IsMaintenance, MaintenanceValues.Inactive);
    }

    const token =
      BrowserStorageService.get(BrowserStorageKeys.AccessToken) ??
      BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

    if (token) {
      if (error.response?.status === 401) {
        store.dispatch(authActions.signOut());
      }
      if (error.response?.status === 403) {
        store.dispatch(authActions.userInfoRequest({}));
      }
    }

    return Promise.reject(error);
  },
);

export default client;
