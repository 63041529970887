import { forwardRef } from 'react';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';

import { Calendar, Clear } from 'assets/icons';

import styles from '../DatePicker.module.scss';

import 'react-datepicker/dist/react-datepicker.css';

const CustomInput = forwardRef((props: any) => {
  const { containerRef, handleClearDate, onClick, value } = props;

  const isOpen = containerRef?.current?.state?.open;
  const btnClass = classNames(styles.custom_input, {
    [styles.custom_input__open]: isOpen,
  });

  return (
    <button className={btnClass} onClick={onClick}>
      {value ? <span>{value}</span> : <span className={styles.placeholder}>Select period</span>}
      <div className={styles.custom_input__controls}>
        {value ? (
          <Tooltip followCursor={true} placement='left-start' title='Clear'>
            <Clear
              onClick={(event: any) => {
                event.stopPropagation();
                handleClearDate();
              }}
              role='button'
            />
          </Tooltip>
        ) : null}
        <Tooltip followCursor={true} placement='left-start' title='Calendar'>
          <Calendar />
        </Tooltip>
      </div>
    </button>
  );
});

export default CustomInput;
