import { Navigate, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService, MaintenanceValues } from 'services';
import { authActions, authSelectors } from 'store/authSlice';
import { userInfoRequest } from 'store/authSlice/thunks';
import { RoutesData, StatusType } from 'types';

const ProtectedRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = BrowserStorageService.get(BrowserStorageKeys.AccessToken);
  const personalInfo = useAppSelector(authSelectors.selectPersonalInfo);

  const isMaintenance =
    BrowserStorageService.get(BrowserStorageKeys.IsMaintenance) === MaintenanceValues.Active;

  if (isMaintenance) {
    return navigate(0);
  }

  if (!token && !isMaintenance) {
    return <Navigate to={RoutesData.Login} replace />;
  }

  if (token && !personalInfo) {
    dispatch(userInfoRequest({}));
  }

  if (
    (personalInfo?.state?.status === StatusType.BLOCKED ||
      personalInfo?.state?.status === StatusType.DELETED ||
      personalInfo?.state?.status === StatusType.SUSPENDED) &&
    !isMaintenance
  ) {
    dispatch(authActions.signOut());
    return <Navigate to={RoutesData.Login} replace />;
  }

  return children;
};

export default ProtectedRoute;
