import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import styles from './Table.module.scss';
import { ICustomTable } from './types';

const CustomTable = ({ children, headerArray, maxHeight }: ICustomTable) => {
  return (
    <Paper sx={{ width: '100%', overflowY: 'auto' }}>
      <TableContainer style={{ maxHeight }}>
        <Table stickyHeader className={styles.inner}>
          <TableHead className={styles.container__header}>
            <TableRow className={styles.container__header__row}>
              {headerArray.map(({ id, label, isArray }: any) => {
                return (
                  <TableCell align='left' className={styles.container__header__ceil} key={id}>
                    {isArray ? (
                      label.map((element: string) => (
                        <span key={element}>
                          <span>{element}</span>
                          <br />
                        </span>
                      ))
                    ) : (
                      <span>{label}</span>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default CustomTable;
