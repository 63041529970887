import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { calcsDecimals } from 'utils';

import styles from './DashboardChart.module.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

function PerfomanceChart({ data, labels, title }) {
  const scales = {
    x: {
      grid: {
        drawTicks: true,
        color: function (context) {
          if (context?.tick?.label.includes('20')) {
            return '#142427';
          } else {
            return '#e4e5e5';
          }
        },
        tickColor: function (context) {
          if (context?.tick?.label.includes('20')) {
            return '#142427';
          } else {
            return '#e4e5e5';
          }
        },
      },
      title: {
        align: 'end',
        display: true,
        color: '#142427',
        font: {
          family: 'Inter',
          size: 13,
          weight: 500,
        },
        padding: { top: 20 },
        text: 'Date',
      },
    },
    y: {
      grid: {
        color: '#e4e5e5',
        tickColor: '#e4e5e5',
      },
      position: 'left',
      ticks: {
        // callback: (val, index, ticks) => (index === 0 || index === ticks.length - 1 ? null : val),
      },
    },
  };

  const options = {
    chart: {
      type: 'bar',
      fontFamily: 'Inter',
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
    responsive: true,
    scales: scales,
  };

  const lineData = {
    labels: labels.map((el) => {
      if (moment.utc(el).year() === 2024 && moment.utc(el).month() === 0) {
        return moment.utc(el).format('MMM YYYY');
      }
      return moment.utc(el).format('MMM');
    }),
    datasets: [
      {
        label: '',
        data: data?.chart?.map((el) =>
          Number(calcsDecimals.toCoin(Number(el?.commissionsSum), 'USDC').toFixed()),
        ),
        fill: false,
        backgroundColor: '#0B688C',
        borderRadius: 8,
        borderWidth: 0,
        tension: 0,
      },
    ],
  };

  return (
    <>
      <p className={styles.title}>{title}</p>
      <p className={styles.sub_title}>Value, USDC</p>
      <Bar options={options} data={lineData} />
    </>
  );
}

export default PerfomanceChart;
