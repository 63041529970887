import { CalendarContainer } from 'react-datepicker';
import classNames from 'classnames';

import dateRangeTabs from 'constants/dateRange';

import styles from '../DatePicker.module.scss';

import 'react-datepicker/dist/react-datepicker.css';

const Container = (props: any) => {
  const { activeFormat, children, setActiveFormat } = props;
  return (
    <div className={styles.container}>
      <div className={styles.container__actions}>
        {dateRangeTabs.map((item: any) => {
          return (
            <div
              className={classNames(styles.container__actions__item, {
                [styles.container__actions__item__active]: item.id === activeFormat,
              })}
              key={item.id}
              onClick={() => setActiveFormat(item.id)}
            >
              {item.name}
            </div>
          );
        })}
      </div>
      <CalendarContainer className='react-datepicker'>
        <div style={{ position: 'relative' }}>{children}</div>
      </CalendarContainer>
    </div>
  );
};

export default Container;
