import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { BigNews } from 'assets/icons';
import { BlockWrapper } from 'components/layouts';
import { Loader } from 'components/shared';
import { RoutesData } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { dashboardActions, dashboardSelectors } from 'store/dashboardSlice';

import styles from './NewsDetails.module.scss';

const displayNoneStyle = { display: 'none' };

const NewsDetails = () => {
  const params = useParams();
  const { id } = params;

  const dispatch = useAppDispatch();
  const newsData = useAppSelector(dashboardSelectors.selectNewsData);

  const [isLoaded, setIsLoaded] = useState(false);
  const style = isLoaded ? {} : displayNoneStyle;

  useEffect(() => {
    if (id) {
      dispatch(dashboardActions.getNews(id));
    }
  }, [dispatch, id]);

  return (
    <div style={{ height: '100vh', width: '90%' }}>
      <BlockWrapper
        backTo='Back to dashboard'
        backToPath={RoutesData.Dashboard}
        marginBottom='0px'
        navigationPath={`${RoutesData.Dashboard}/news`}
        navTo='View all news'
      >
        {newsData?.loading ? (
          <Loader />
        ) : (
          <div className={styles.wrapper}>
            <h1 className={styles.wrapper__title}>{newsData?.data?.name}</h1>
            <span className={styles.wrapper__date}>
              {moment(newsData?.data?.publishAt).format('D MMM YYYY')}
            </span>
            <div className={styles.wrapper__img}>
              <img
                onLoad={() => setIsLoaded(true)}
                src={newsData?.data?.image}
                alt={id}
                style={style}
              />
              {isLoaded ? null : <BigNews />}
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: newsData?.data?.body }}
              className={styles.wrapper__text}
            ></p>
          </div>
        )}
      </BlockWrapper>
    </div>
  );
};

export default NewsDetails;
