import { forwardRef } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Tooltip } from '@mui/material';
import isNil from 'lodash/isNil';
import classNames from 'classnames';

import { Clear } from 'assets/icons';

import styles from './Input.module.scss';
import { IInput } from './types';

const CustomInput = forwardRef<any, IInput>(
  (
    {
      adornment,
      className,
      disabled,
      error,
      helperText = '',
      id,
      maxLength,
      minLength,
      name,
      label,
      onBlur,
      onChange,
      onClear,
      onFocus,
      onEnter,
      placeHolder = '',
      readonly,
      type = 'text',
      value,
      view = 'simple',
      withClear = false,
      ...rest
    },
    ref,
  ) => {
    const handleKeyDown = (e: any) => {
      if (e.keyCode === 13) {
        onEnter();
      }
    };

    const inputClassNames = classNames(styles.simple_input, className, {
      [styles.simple_input__error]: !isNil(error),
    });

    const inputComponents = {
      simple: (
        <input
          {...rest}
          autoComplete='off'
          className={inputClassNames}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          minLength={minLength}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={(e) => handleKeyDown(e)}
          placeholder={placeHolder}
          ref={ref}
          type={type}
          value={value}
          readOnly={readonly}
        />
      ),
      endAdornment: (
        <>
          <OutlinedInput
            {...rest}
            autoComplete='off'
            disabled={disabled}
            endAdornment={<InputAdornment position='end'>{adornment}</InputAdornment>}
            error={!isNil(error)}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={(e) => handleKeyDown(e)}
            ref={ref}
            placeholder={placeHolder}
            type={type}
            value={value}
          />
          <div className={styles.helper_text}>{helperText}</div>
        </>
      ),
      startAdornment: (
        <>
          <OutlinedInput
            {...rest}
            autoComplete='off'
            className={inputClassNames}
            disabled={disabled}
            error={!isNil(error)}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={(e) => handleKeyDown(e)}
            placeholder={placeHolder}
            ref={ref}
            startAdornment={<InputAdornment position='start'>{adornment}</InputAdornment>}
            type={type}
            value={value}
          />

          <div className={styles.helper_text}>{helperText}</div>
        </>
      ),
    };

    return (
      <div className={styles.wrapper}>
        {label ? <span className={styles.wrapper__label}>{label}</span> : null}
        {inputComponents[view]}
        {!isNil(error) || error ? (
          <span className={styles.wrapper__error}>{error?.message || error}</span>
        ) : null}
        {withClear && (
          <Tooltip followCursor={true} placement='top' title='Clear'>
            <Clear className={styles.clear} onClick={onClear} role='button' />
          </Tooltip>
        )}
      </div>
    );
  },
);

export default CustomInput;
