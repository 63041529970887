import { ChangeEvent } from 'react';
import TableBody from '@mui/material/TableBody';

import { EmptyData, Loader, Pagination, ScrollWrapper, Table } from 'components/shared';
import { payoutsTable } from 'constants/index';
import { useAppSelector } from 'hooks';
import { payoutsSelectors } from 'store/payoutsSlice';
import { ITableTypes } from 'types';

import PayoutsRow from './PayoutsRow';
import styles from './PayoutsTable.module.scss';

const PayoutsTable = ({ setSkip, setTake, skip, take }: ITableTypes) => {
  const data = useAppSelector(payoutsSelectors.selectPayoutsData);
  const loading = useAppSelector(payoutsSelectors.selectPayoutsLoading);
  const totalCount = data?.totalCount || 1;

  const handlePage = (_event: unknown, newPage: number) => {
    setSkip(newPage * take);
  };
  const handleRows = (event: ChangeEvent<HTMLInputElement>) => {
    setTake(event.target.value);
    setSkip(0);
  };

  return (
    <>
      <div>
        <h3 className={styles.table_title}>Affiliate Payouts</h3>
        <ScrollWrapper>
          {loading ? (
            <Loader />
          ) : (
            <Table headerArray={payoutsTable}>
              <TableBody>
                {data?.list?.map((row: any) => (
                  <PayoutsRow row={row} key={row.paidAt + row.amount} />
                ))}
              </TableBody>
            </Table>
          )}
          {!data.totalCount && !loading && <EmptyData />}
        </ScrollWrapper>
      </div>

      <Pagination
        handleChangePage={handlePage}
        handleChangeRowsPerPage={handleRows}
        currentPage={skip / take || 0}
        rowsPerPage={take}
        totalCount={totalCount}
      />
    </>
  );
};
export default PayoutsTable;
