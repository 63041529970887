import * as yup from 'yup';

const validationConfirmRegistration = yup.object({
  password: yup.string().required('* Enter password to finish registration').min(8),
  confirmPassword: yup
    .string()
    .required('* Repeat password to finish registration')
    .oneOf([yup.ref('password')], '* Passwords must match'),
});

export default validationConfirmRegistration;
