import { TablePagination } from '@mui/material';
import classNames from 'classnames';

import styles from './Pagination.module.scss';
import { IPaginationProps } from './types';
import TablePaginationActions from './PaginationAction';

const Pagination = ({
  handleChangePage,
  handleChangeRowsPerPage,
  currentPage,
  rowsPerPage,
  totalCount = 1,
  rowsPerPageOptions = [10, 20],
  className,
}: IPaginationProps): JSX.Element => {
  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component='div'
      className={classNames(className, styles.wrapper)}
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={
        <span
          style={{
            border: ' 1px solid #e1e1e1',
            borderRadius: '8px',
            padding: '8px 12px',
          }}
        >
          Row size:
        </span>
      }
      ActionsComponent={TablePaginationActions}
      showFirstButton={true}
      showLastButton={true}
    />
  );
};

export default Pagination;
