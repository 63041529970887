import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { authApi } from 'api';
import { Eye, Logo, NoEye } from 'assets/icons';
import { BlockWrapper, Button, Copyright, Input } from 'components';
import { authService, BrowserStorageKeys, BrowserStorageService } from 'services';
import { validationConfirmRegistration } from 'utils';

import { ERRORS, ERROR_CODES } from '../../../constants';

import { IConfirmRegistration } from './types';
import { LinkError, Loading, Success } from './components';
import styles from './ConfirmRegistration.module.scss';

const defaultValues: IConfirmRegistration = {
  password: '',
  confirmPassword: '',
};

const ConfirmRegistration: React.FC = () => {
  const [error, setError] = useState('');
  const [linkErrorMessage, setLinkErrorMessage] = useState('* Something went wrong');
  const [showPass, setShowPass] = useState(false);

  const [contentView, setContentView] = useState<'loading' | 'password' | 'success' | 'linkError'>(
    'loading',
  );

  useEffect(() => {
    const generateError = (error: string) => {
      setContentView('linkError');
      setLinkErrorMessage(error);
    };
    const verifyUrlParams = async () => {
      try {
        const response: any = await authService.verifyUrlParams();
        if (response?.response?.status === ERROR_CODES[409]) {
          generateError(ERRORS[409]);
        } else if (response?.response?.status === ERROR_CODES[410]) {
          generateError(ERRORS[410]);
        } else if (response?.response?.status === ERROR_CODES[404]) {
          generateError(ERRORS[404]);
        } else if (response?.response?.status === ERROR_CODES[403]) {
          generateError(ERRORS[403]);
        } else {
          setContentView('password');
        }
      } catch (error) {
        setContentView('linkError');
      }
    };
    verifyUrlParams();
  }, []);

  const form: UseFormReturn<IConfirmRegistration, UseFormProps> = useForm<IConfirmRegistration>({
    defaultValues,
    resolver: yupResolver(validationConfirmRegistration),
  });

  const passwordField = form.register('password');
  const confirmPasswordField = form.register('confirmPassword');

  const resetForm = useCallback(() => {
    form.reset(defaultValues);
  }, [form]);

  const handleConfirm = async ({ password, confirmPassword }: IConfirmRegistration) => {
    const userId = BrowserStorageService.get(BrowserStorageKeys.UserId, { session: true });

    const credentials = {
      userId: Number(userId),
      password: confirmPassword || password,
    };
    try {
      if (userId) {
        const response = await authApi.setPasswordRequest(credentials);
        if (response.status === 200) {
          setContentView('success');
        }
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setError(error?.response?.data?.message);
      } else {
        setError('* Something went wrong');
      }
    }
  };

  const type = showPass ? 'text' : 'password';
  const icon = showPass ? <Eye /> : <NoEye />;

  useEffect(() => {
    return () => resetForm();
  }, [resetForm]);

  const renderComponentContent = (view: string) => {
    switch (view) {
      case 'linkError':
        return <LinkError linkErrorMessage={linkErrorMessage} />;
      case 'loading':
        return <Loading />;
      case 'success':
        return <Success />;
      default:
        return (
          <FormProvider {...form}>
            <form autoComplete='off' onSubmit={form.handleSubmit(handleConfirm)}>
              <BlockWrapper marginBottom='0'>
                <div className={styles.wrapper}>
                  <Logo />
                  <div className={styles.wrapper__title}>Create password</div>
                  <div className={styles.wrapper__subtitle}>
                    To confirm your registration, you need to create a password.
                  </div>
                  <div className={styles.wrapper__form_box}>
                    <Input
                      {...passwordField}
                      adornment={
                        <span
                          className={styles.adornment}
                          role='button'
                          onClick={() => setShowPass(!showPass)}
                        >
                          {icon}
                        </span>
                      }
                      error={form.formState.errors.password?.message}
                      helperText='Must be at least 8 characters'
                      label='Password'
                      name={passwordField.name}
                      onChange={(value: any) => {
                        setError('');
                        passwordField.onChange(value);
                      }}
                      placeHolder='Enter password'
                      type={type}
                      view='endAdornment'
                    />
                    <Input
                      {...confirmPasswordField}
                      adornment={
                        <span
                          className={styles.adornment}
                          role='button'
                          onClick={() => setShowPass(!showPass)}
                        >
                          {icon}
                        </span>
                      }
                      error={form.formState.errors.confirmPassword?.message}
                      helperText='Both passwords must match'
                      label='Confirm Password'
                      name={confirmPasswordField.name}
                      onChange={(value: any) => {
                        setError('');
                        confirmPasswordField.onChange(value);
                      }}
                      placeHolder='Re-enter password'
                      type={type}
                      view='endAdornment'
                    />
                  </div>
                  <span className={styles.error}>{error}</span>

                  <Button title='Save' type='submit' />
                </div>
              </BlockWrapper>
            </form>
          </FormProvider>
        );
    }
  };

  return (
    <div className={styles.container}>
      {renderComponentContent(contentView)}
      <Copyright />
    </div>
  );
};

export default ConfirmRegistration;
