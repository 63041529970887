import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';

import { commissionsApi } from 'api';
import { Slice } from 'types';

export const getTransactions = createAsyncThunk(
  `${Slice.Commissions}/xab-transactions`,
  async (
    credentials: {
      skip: number;
      take: number;
      sort: string;
      order: string;
      globalSearch?: string;
      dateFrom?: string;
      dateTo?: string;
    },
    thunkAPI,
  ) => {
    const { ...restCredentials } = credentials;

    try {
      const response = await commissionsApi.getTransactionsRequest(restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const downloadTransactions = createAsyncThunk(
  `${Slice.Commissions}/download-transactions`,
  async (
    credentials: {
      skip: number;
      take: number;
      sort: string;
      order: string;
      dateFrom?: string;
      dateTo?: string;
    },
    thunkAPI,
  ) => {
    const { ...restCredentials } = credentials;

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      const response = await commissionsApi.downloadTransactionsRequest(restCredentials);
      const xlsData = new Blob([response.data], { type: fileType });
      FileSaver.saveAs(xlsData, 'commissions' + fileExtension);
      return;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);
