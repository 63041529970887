import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useController, UseControllerReturn, useFormContext } from 'react-hook-form';

import { Eye, Logo, NoEye } from 'assets/icons';
import { Button, Input } from 'components';
import { RoutesData } from 'types';

import styles from '../Login.module.scss';

const LoginForm: React.FC = () => {
  const { control } = useFormContext();

  const [showPass, setShowPass] = useState(false);
  const type = showPass ? 'text' : 'password';
  const icon = showPass ? <Eye /> : <NoEye />;

  const controllerEmail: UseControllerReturn = useController({
    name: 'email',
    control,
  });
  const controllerPassword: UseControllerReturn = useController({
    name: 'password',
    control,
  });

  return (
    <div className={styles.wrapper}>
      <Logo />
      <div className={styles.wrapper__form_box}>
        <Input
          error={controllerEmail.fieldState.error}
          label='Login'
          name={controllerEmail.field.name}
          onChange={controllerEmail.field.onChange}
          placeHolder='Enter email'
          ref={controllerEmail.field.ref}
          value={controllerEmail.field.value}
        />
        <Input
          adornment={
            <span className={styles.adornment} role='button' onClick={() => setShowPass(!showPass)}>
              {icon}
            </span>
          }
          error={controllerPassword.fieldState.error}
          label='Password'
          name={controllerPassword.field.name}
          onChange={controllerPassword.field.onChange}
          placeHolder='Enter password'
          type={type}
          ref={controllerPassword.field.ref}
          value={controllerPassword.field.value}
          view='endAdornment'
        />
        <Link to={`${RoutesData.Login}/forgot-password`} className={styles.forgot_pass}>
          Forgot Password?
        </Link>
      </div>
      <Button title='Login' type='submit' />
    </div>
  );
};

export default LoginForm;
