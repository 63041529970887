import { useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Event } from 'assets/icons';
import { RoutesData } from 'types';
import { cutStringByLength } from 'utils';

import styles from './WidgetItem.module.scss';

const displayNoneStyle = { display: 'none' };

function WidgetItem({ id, img, title = '', date }: any) {
  const titleRef = useRef<any>({});
  const [isLoaded, setIsLoaded] = useState(false);
  const style = isLoaded ? {} : displayNoneStyle;

  const [controlLength, setControlLength] = useState(0);

  useLayoutEffect(() => {
    setControlLength(Math.round(Number(titleRef?.current?.offsetWidth) / 8));
  }, [titleRef?.current?.offsetWidth]);

  return (
    <Link to={`${RoutesData.Dashboard}/calendar/${id}`} className={styles.container}>
      <div className={styles.container__picture}>
        <img alt={title} onLoad={() => setIsLoaded(true)} src={img} style={style} />
        {isLoaded ? null : <Event />}
      </div>
      <div className={styles.container__details}>
        <span className={styles.container__details__title} ref={titleRef}>
          {cutStringByLength(title, 0, controlLength, controlLength)}
        </span>
        <span className={styles.container__details__time}>
          {moment.utc(date).format('HH:mm')} CET
        </span>
      </div>
    </Link>
  );
}

export default WidgetItem;
