export const AffiliateBonusTransaction = {
  ALL: 'ALL',
  XAB_LOCK: 'XAB_LOCK',
  XAB_UNLOCK: 'XAB_UNLOCK',
  BONUS_CONVERT: 'BONUS_CONVERT',
  BONUS_PAYOUT: 'BONUS_PAYOUT',
  BONUS_REINVEST: 'BONUS_REINVEST',
  XAB_SYSTEM_UNLOCK: 'XAB_SYSTEM_UNLOCK',
};

export const AffiliateXabDepositTransaction = {
  ALL: 'ALL',
  ADMIN_DEPOSIT: 'ADMIN_DEPOSIT',
  REFILL_BY_CAPITEUS: 'REFILL_BY_CAPITEUS',
  XAB_PURCHASE_CRYPTO: 'XAB_PURCHASE_CRYPTO',
  XAB_PURCHASE_OTC: 'XAB_PURCHASE_OTC',
  XAB_RETURN: 'XAB_RETURN',
};

export const XabDepositTransactionTypes = {
  ADMIN_DEPOSIT: 'Admin Deposit',
  REFILL_BY_CAPITEUS: 'Capiteus',
  XAB_PURCHASE_CRYPTO: 'Purchase',
  XAB_PURCHASE_OTC: 'Bank Transfer',
  XAB_RETURN: 'XAB Return',
};

const XABOpt = [
  { label: 'All', value: AffiliateXabDepositTransaction.ALL },
  { label: 'Admin Deposit', value: AffiliateXabDepositTransaction.ADMIN_DEPOSIT },
  { label: 'Capiteus', value: AffiliateXabDepositTransaction.REFILL_BY_CAPITEUS },
  { label: 'Purchase', value: AffiliateXabDepositTransaction.XAB_PURCHASE_CRYPTO },
  { label: 'Bank Transfer', value: AffiliateXabDepositTransaction.XAB_PURCHASE_OTC },
  { label: 'XAB Return', value: AffiliateXabDepositTransaction.XAB_RETURN },
];

export const BonusTransactionTypes = {
  XAB_LOCK: 'XAB Locking',
  XAB_UNLOCK: 'Release Locking',
  BONUS_CONVERT: 'Bonus Conversion',
  BONUS_PAYOUT_SUCCESS: 'Payout',
  BONUS_PAYOUT_REJECT: 'Payout Rejected',
  BONUS_REINVEST: 'Reinvest Bonus',
  XAB_SYSTEM_UNLOCK: 'XAB System Unlock',
};

const bonusOpt = [
  { label: 'All', value: AffiliateBonusTransaction.ALL },
  { label: 'XAB Locking', value: AffiliateBonusTransaction.XAB_LOCK },
  { label: 'Release Locking', value: AffiliateBonusTransaction.XAB_UNLOCK },
  { label: 'Bonus Conversion', value: AffiliateBonusTransaction.BONUS_CONVERT },
  { label: 'Payout', value: AffiliateBonusTransaction.BONUS_PAYOUT },
  { label: 'Reinvest Bonus', value: AffiliateBonusTransaction.BONUS_REINVEST },
  { label: 'XAB System Unlock', value: AffiliateBonusTransaction.XAB_SYSTEM_UNLOCK },
];

const selectOptions = {
  bonusOpt,
  XABOpt,
};

export default selectOptions;
