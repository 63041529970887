import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

import { calcsDecimals, checkIfValueIsEmpty } from 'utils';

import styles from './NestedPartnersTable.module.scss';

const NestedPartnersRow = ({ row }: any) => (
  <>
    <TableRow className={styles.container__body__row}>
      <TableCell align='left' className={styles.ceil}>
        {checkIfValueIsEmpty(row?.firstName)}
        {checkIfValueIsEmpty(row?.lastName)}
      </TableCell>
      <TableCell align='center' className={styles.ceil}>
        {row?.userEmail}
      </TableCell>
      <TableCell align='center' className={styles.ceil}>
        {row?.eurUsdRate} USD
      </TableCell>
      <TableCell align='center' className={styles.ceil}>
        {calcsDecimals.toCoin(row?.amountTo, 'XAB').toFixed()}
      </TableCell>
      <TableCell align='center' className={styles.ceil}>
        {calcsDecimals.toCoin(row?.amountFrom, row?.currencyFrom).toFixed()} {row?.currencyFrom}
      </TableCell>
      <TableCell align='center' className={styles.ceil}>
        {row?.xabRate && Number(row?.xabRate)}
      </TableCell>
      <TableCell align='center' className={styles.ceil}>
        {calcsDecimals.toCoin(row?.fee, row?.currencyFrom).toFixed()} {row?.currencyFrom}
      </TableCell>
      <TableCell align='right' className={styles.ceil}>
        {moment(row?.createdAt).utc().format('MMM DD, YYYY hh:mm A')}
      </TableCell>
    </TableRow>
  </>
);

export default NestedPartnersRow;
