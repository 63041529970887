import { HeadCell } from 'types';

const payoutsTable: HeadCell[] = [
  {
    id: 'administrator',
    value: 'administrator',
    label: 'Administrator',
  },
  {
    id: 'amount',
    value: 'amount',
    label: 'Amount, USDC',
  },
  {
    id: 'date',
    value: 'date',
    label: 'Date and time',
  },
];

export default payoutsTable;
