import { Link } from 'react-router-dom';

import { BackPage } from 'assets/icons';

import styles from './BlockWrapper.module.scss';
import { IBlockWrapper } from './types';

const BlockWrapper = ({
  backTo,
  backToPath,
  children,
  marginBottom,
  navigationPath,
  navTo,
  padding,
  rightSideElement,
  title,
}: IBlockWrapper) => {
  return (
    <div className={styles.wrapper} style={{ padding: padding ? padding : '24px' }}>
      <div
        className={styles.wrapper__header}
        style={{ marginBottom: marginBottom ? marginBottom : '44px' }}
      >
        {backTo || navTo || rightSideElement ? (
          <div className={styles.wrapper__header__navigation}>
            {backTo && backToPath ? (
              <Link to={backToPath} className={styles.wrapper__header__navigation__item}>
                <BackPage />
                {backTo}
              </Link>
            ) : null}
            {navTo && navigationPath ? (
              <Link to={navigationPath} className={styles.wrapper__header__navigation__item}>
                {navTo}
              </Link>
            ) : null}
            {rightSideElement ? rightSideElement : null}
          </div>
        ) : null}

        {title ? <div className={styles.wrapper__header__title}>{title}</div> : null}
      </div>
      {children}
    </div>
  );
};

export default BlockWrapper;
