import { IToast } from './types';

const Toast = ({
  insideBlockClassName,
  showToast = false,
  message = '',
  toastClassNames,
}: IToast): JSX.Element => {
  return (
    <>
      {showToast ? (
        <div className={toastClassNames}>
          <div className={insideBlockClassName}>
            <span>{message}</span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Toast;
