import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

import { calcsDecimals } from 'utils';

import styles from './PayoutsTable.module.scss';

const PayoutsRow = ({ row }: any) => (
  <>
    <TableRow className={styles.container__body__row}>
      <TableCell align='left' className={styles.ceil}>
        {row?.byUser?.email}
      </TableCell>
      <TableCell align='center' className={styles.ceil}>
        {calcsDecimals.toCoin(row?.amount, 'USDC').toFixed()}
      </TableCell>
      <TableCell align='right' className={styles.ceil}>
        {moment(row?.paidAt).utc().format('MMM DD, YYYY hh:mm A')}
      </TableCell>
    </TableRow>
  </>
);

export default PayoutsRow;
