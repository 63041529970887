import { memo } from 'react';

import { INestedPartnersWidget } from './types';
import styles from './NestedPartnersWidget.module.scss';

function NestedPartnersWidget({
  currency,
  subCurrency = '',
  subTitle,
  subValue = '',
  title,
  value,
}: INestedPartnersWidget) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__block_title}>
        <span className={styles.title}>{title}</span>
        <span className={styles.sub_title}>{subTitle}</span>
      </div>
      <div className={styles.wrapper__block_value}>
        <div className={styles.wrapper__block_value__main_value}>
          <span className={styles.value}>{value}</span>
          <span className={styles.currency}>{currency}</span>
        </div>
        {subValue ? (
          <div className={styles.wrapper__block_value__secondary_value}>
            <span className={styles.sub_value}>{subValue}</span>
            <span className={styles.sub_currency}>{subCurrency}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default memo(NestedPartnersWidget);
