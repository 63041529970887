import { createAsyncThunk } from '@reduxjs/toolkit';

import { dashboardApi } from 'api';
import { Slice } from 'types';

export const getCommissionsSum = createAsyncThunk(
  `${Slice.Dashboard}/commission-sum`,
  async (_, thunkAPI) => {
    try {
      const response = await dashboardApi.getCommissionsSumRequest();
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const getReinvestedTokensSum = createAsyncThunk(
  `${Slice.Dashboard}/reinvested-tokens-sum`,
  async (
    credentials: {
      dateFrom?: string;
      dateTo?: string;
    },
    thunkAPI,
  ) => {
    try {
      const response = await dashboardApi.getReinvestedTokensSumRequest(credentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const getReinvestedTokens = createAsyncThunk(
  `${Slice.Dashboard}/reinvested-tokens`,
  async (
    credentials: {
      skip: number;
      take: number;
      sort: string;
      order: string;
      dateFrom?: string;
      dateTo?: string;
    },
    thunkAPI,
  ) => {
    const { ...restCredentials } = credentials;

    try {
      const response = await dashboardApi.getReinvestedTokensRequest(restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);
export const getNewsList = createAsyncThunk(
  `${Slice.Dashboard}/company-news`,
  async (
    credentials: {
      skip: number;
      take: number;
      sort: string;
      order: string;
      dateFrom?: string;
      dateTo?: string;
    },
    thunkAPI,
  ) => {
    const { ...restCredentials } = credentials;
    try {
      const response = await dashboardApi.getNewsListRequest(restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);
export const getNews = createAsyncThunk(
  `${Slice.Dashboard}/company-news-details`,
  async (id: string, thunkAPI) => {
    try {
      const response = await dashboardApi.getNewsRequest(id);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const getSalesChart = createAsyncThunk(
  `${Slice.Dashboard}/sales-chart`,
  async (_, thunkAPI) => {
    try {
      const response = await dashboardApi.getSalesChartRequest();
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const getEvent = createAsyncThunk(
  `${Slice.Dashboard}/event-details`,
  async (id: string, thunkAPI) => {
    try {
      const response = await dashboardApi.getEventRequest(id);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const getEventsList = createAsyncThunk(
  `${Slice.Dashboard}/events-list`,
  async (
    credentials: {
      skip: number;
      take: number;
      sort: string;
      order: string;
      dateFrom?: string;
      dateTo?: string;
    },
    thunkAPI,
  ) => {
    const { ...restCredentials } = credentials;

    try {
      const response = await dashboardApi.getEventsListRequest(restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);
