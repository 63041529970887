import { createAsyncThunk } from '@reduxjs/toolkit';

import { Slice } from 'types';
import { profileApi } from 'api';

export const getProfile = createAsyncThunk(
  `${Slice.Profile}/affiliate/profile`,
  async (_, thunkAPI) => {
    try {
      const response = await profileApi.getProfileRequest();
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const updatePassword = createAsyncThunk(`${Slice.Profile}/update/pass`, async () => {
  try {
    const response = await profileApi.updatePasswordRequest();
    return response;
  } catch (error) {
    return error;
  }
});
