import classNames from 'classnames';

import styles from './Button.module.scss';
import { IButton } from './types';

const Button = ({ callback, className, disabled, Icon, title, type }: IButton): JSX.Element => {
  const buttonClass: string = classNames(styles.btn_container, className);

  return (
    <button className={buttonClass} disabled={disabled} onClick={callback} type={type}>
      {Icon ? <Icon /> : null}
      {title}
    </button>
  );
};

export default Button;
