import TableBody from '@mui/material/TableBody';

import { EmptyData, Loader, Pagination, ScrollWrapper, Table } from 'components/shared';
import { nestedPartnersTable } from 'constants/index';
import { useAppSelector } from 'hooks';
import { nestedPartnersSelectors } from 'store/nestedSlice';
import { ITableTypes } from 'types';

import NestedPartnersRow from './NestedPartnersRow';
import styles from './NestedPartnersTable.module.scss';

const NestedPartnersTable = ({ setSkip, setTake, skip, take }: ITableTypes) => {
  const nestedPartnerByIdData = useAppSelector(nestedPartnersSelectors.selectNestedPartnerByIdData);
  const loading = nestedPartnerByIdData?.loading;
  const totalCount = Number(nestedPartnerByIdData?.data?.xabPurchaseTransactions?.totalCount) || 1;
  const list = nestedPartnerByIdData?.data?.xabPurchaseTransactions?.list;

  const handlePage = (_event: unknown, newPage: number) => {
    setSkip(newPage * take);
  };
  const handleRows = (event: any) => {
    setTake(event.target.value);
    setSkip(0);
  };

  return (
    <>
      <div>
        <h3 className={styles.table_title}>XAB Purchase transactions</h3>
        {loading ? (
          <Loader />
        ) : (
          <ScrollWrapper>
            <Table headerArray={nestedPartnersTable}>
              <TableBody>
                {list?.map((row: any) => (
                  <NestedPartnersRow row={row} key={row.createdAt} />
                ))}
              </TableBody>
            </Table>
            {!nestedPartnerByIdData?.data?.xabPurchaseTransactions?.totalCount && <EmptyData />}
          </ScrollWrapper>
        )}
        <Pagination
          handleChangePage={handlePage}
          handleChangeRowsPerPage={handleRows}
          currentPage={skip / take || 0}
          rowsPerPage={take}
          totalCount={totalCount}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      </div>
    </>
  );
};
export default NestedPartnersTable;
