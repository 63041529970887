import { useState, useRef, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';

import { DatesType } from 'constants/dateRange';

import { Container, CustomInput, DayContents } from './components';
import { IDatePicker } from './types';
import styles from './DatePicker.module.scss';

import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({ isExternalClear = false, onChangeDate }: IDatePicker) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [activeFormat, setActiveFormat] = useState('dd/MMMM/yyyy');

  const containerRef = useRef<any>(null);

  const monthYearPicker = activeFormat === DatesType.month;
  const quarterYearPicker = activeFormat === DatesType.quarter;
  const yearPicker = activeFormat === DatesType.year;

  const handleDate = (dates: any) => {
    const [start, end] = dates;
    setEndDate(end);
    setStartDate(start);
    onChangeDate && onChangeDate(start, activeFormat, end);
  };

  const handleClearDate = useCallback(() => {
    setEndDate(null);
    setStartDate(null);
    onChangeDate && onChangeDate('', activeFormat, '');
  }, [activeFormat, onChangeDate]);

  useEffect(() => {
    if (isExternalClear) {
      handleClearDate();
    }
  }, [isExternalClear, handleClearDate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__label}>Date Range</div>
      <DatePicker
        calendarContainer={({ children }: any) => (
          <Container activeFormat={activeFormat} setActiveFormat={setActiveFormat}>
            {children}
          </Container>
        )}
        customInput={<CustomInput containerRef={containerRef} handleClearDate={handleClearDate} />}
        dateFormat={activeFormat}
        endDate={endDate}
        maxDate={new Date()}
        onChange={handleDate}
        selected={startDate}
        selectsRange
        startDate={startDate}
        showMonthYearPicker={monthYearPicker}
        showPopperArrow={false}
        showQuarterYearPicker={quarterYearPicker}
        showYearPicker={yearPicker}
        renderYearContents={<DayContents />}
        ref={containerRef}
      />
    </div>
  );
};

export default CustomDatePicker;
