import { useNavigate } from 'react-router-dom';

import { Logo } from 'assets/icons';
import { BlockWrapper, Button } from 'components';
import { RoutesData } from 'types';

import styles from '../SetNewPassword.module.scss';

const Success: React.FC = () => {
  const navigate = useNavigate();

  return (
    <BlockWrapper marginBottom='0'>
      <div className={styles.wrapper}>
        <Logo />
        <div className={styles.wrapper__title}>Password reset successfully</div>
        <div className={styles.wrapper__subtitle}>Your password has been changed successfully.</div>
        <div style={{ marginTop: '70px' }}></div>
        <Button callback={() => navigate(RoutesData.Login)} title='Login' />
      </div>
    </BlockWrapper>
  );
};

export default Success;
