export enum BrowserStorageKeys {
  Mode = 'mode',
  Role = 'role',
  AccessToken = 'accessToken',
  DeviceToken = 'deviceToken',
  Scroll = 'scroll',
  UserId = 'userId',
  IsTwoFactorAuth = 'isTwoFactorAuth',
  IsMaintenance = 'IsMaintenance',
}

export enum MaintenanceValues {
  Active = 'active',
  Inactive = 'inactive',
}

export type BrowserStorageOptions = {
  session: boolean;
};
