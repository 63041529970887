import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

import { calcsDecimals } from 'utils';

import styles from './CommissionsTable.module.scss';

const CommissionRow = ({ row }: any) => (
  <TableRow className={styles.container__body__row}>
    <TableCell align='left' className={styles.ceil} style={{ textTransform: 'capitalize' }}>
      {row?.firstName ? row?.firstName?.toLowerCase() : ''} <br />
      {row?.lastName ? row?.lastName?.toLowerCase() : ''}
    </TableCell>
    <TableCell align='left' className={styles.ceil}>
      {row?.userEmail}
    </TableCell>
    <TableCell align='left' className={styles.ceil}>
      {row?.eurUsdRate} USD
    </TableCell>
    <TableCell align='center' className={styles.ceil}>
      {calcsDecimals.toCoin(row?.amountTo, 'XAB').toFixed()}
    </TableCell>
    <TableCell align='center' className={styles.ceil}>
      {calcsDecimals.toCoin(row?.amountFrom, row?.currencyFrom).toFixed()} {row?.currencyFrom}
    </TableCell>
    <TableCell align='center' className={styles.ceil}>
      {row?.xabRate && Number(row?.xabRate)}
    </TableCell>
    <TableCell align='center' className={styles.ceil}>
      {calcsDecimals.toCoin(row?.fee, row?.currencyFrom).toFixed()} {row?.currencyFrom}
    </TableCell>
    <TableCell align='center' className={styles.ceil}>
      <span style={{ fontWeight: '600' }} className={styles.commission}>
        {row?.commission ? calcsDecimals.toCoin(row?.commission, 'USDC').toFixed() : 0}
        <span style={{ fontWeight: '400', marginLeft: '3px' }}>USDC</span>
      </span>
    </TableCell>
    <TableCell align='right' className={styles.ceil}>
      {moment(row?.createdAt).utc().format('MMM DD, YYYY hh:mm A')}
    </TableCell>
  </TableRow>
);

export default CommissionRow;
