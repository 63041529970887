import { createSlice } from '@reduxjs/toolkit';

import { Slice } from 'types';

import * as profileThunks from './thunks';
import { ProfileSliceState } from './types';

const internalInitialState: ProfileSliceState = {
  profile: {},
  profileError: null,
  profileloading: false,
};

const profileSlice = createSlice({
  name: Slice.Dashboard,
  initialState: internalInitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(profileThunks.getProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.profileError = null;
        state.profileloading = false;
      })
      .addCase(profileThunks.getProfile.rejected, (state, action) => {
        state.profile = {};
        state.profileError = action.payload;
        state.profileloading = false;
      })
      .addCase(profileThunks.getProfile.pending, (state) => {
        state.profile = {};
        state.profileError = null;
        state.profileloading = true;
      });
  },
});

const { reducer, actions } = profileSlice;

export const profileActions = {
  ...actions,
  ...profileThunks,
};

export * as profileSelectors from './selectors';

export default reducer;
