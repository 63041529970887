export const createUserFriendlyStatus = (status: string) => {
  switch (status) {
    case 'ACTIVE':
      return {
        name: 'Active',
        style: { background: 'rgba(49, 188, 131, 0.07)', color: '#31BC83' },
      };
    case 'SUSPENDED_XAB_RETURN':
      return {
        name: 'Suspended XAB return',
        style: { background: 'rgba(235, 87, 70, 0.07)', color: '#EB5746' },
      };
    case 'SUSPENDED':
      return {
        name: 'Suspended',
        style: { background: 'rgba(235, 87, 70, 0.07)', color: '#EB5746' },
      };
    default:
      return { name: status.toLowerCase().split('_').join(' '), style: { background: '#f7f8f8' } };
  }
};

export default createUserFriendlyStatus;
