import { memo, useEffect, useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import { BlockWrapper } from 'components/layouts';
import { Loader, Slider, WidgetItem } from 'components/shared';
import { useAppDispatch, useAppSelector } from 'hooks';
import { dashboardActions, dashboardSelectors } from 'store/dashboardSlice';
import { enumerateDaysBetweenDates } from 'utils';

import styles from './CalendarWidget.module.scss';

const currentDate = new Date();

const CalendarWidget: React.FC = () => {
  const dispatch = useAppDispatch();
  const eventsData = useAppSelector(dashboardSelectors.selectEventsList);

  const [selectedDayEventsList, setSelectedDayEventsList] = useState<any>([]);
  const [weekArray, setWeekArray] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<any>({
    date: currentDate,
    isEvent: false,
  });

  useEffect(() => {
    const currentWeek = [
      ...enumerateDaysBetweenDates(moment(currentDate), moment().add(8, 'day')).map((day: any) => {
        let isEvent;
        if (!isEmpty(eventsData?.data)) {
          isEvent = eventsData.data.filter((event: any) =>
            moment.utc(event.startAt).isSame(day, 'day'),
          )?.length;
        }
        return {
          date: day,
          isEvent,
        };
      }),
    ];
    setWeekArray(currentWeek);
  }, [eventsData.data]);

  useEffect(() => {
    setSelectedDayEventsList([]);
    if (!isEmpty(eventsData?.data)) {
      const selectedDayEventsArray = eventsData.data.filter((event: any) =>
        moment.utc(event.startAt).isSame(selectedDate.date, 'day'),
      );
      if (selectedDayEventsArray.length) {
        setSelectedDayEventsList(selectedDayEventsArray);
      }
    }
  }, [eventsData.data, selectedDate.date]);

  useEffect(() => {
    dispatch(
      dashboardActions.getEventsList({
        skip: 0,
        take: 10,
        sort: 'createdAt',
        order: 'DESC',
      }),
    );
  }, [dispatch]);

  const isMoreThanOneEvent = selectedDayEventsList?.length > 1;

  return (
    <BlockWrapper marginBottom='0' padding='12px'>
      <div className={styles.wrapper__footer__item__calendar_widget}>
        <div className={styles.wrapper__footer__item__calendar_widget__date}>
          <span className={styles.day}>{moment.utc(selectedDate.date).format('ddd')}</span>
          <span className={styles.date}>{moment.utc(selectedDate.date).format('D')}</span>
          {selectedDayEventsList?.length ? (
            <span className={styles.counter}>
              {isMoreThanOneEvent ? `${selectedDayEventsList?.length} events` : '1 event'}
            </span>
          ) : null}
        </div>

        <div className={styles.wrapper__footer__item__calendar_widget__preview_block}>
          <div className={styles.header}>
            <div className={styles.header__week}>
              {weekArray.map((day: any) => {
                const weekItemClassName = classNames(styles.header__week__item, {
                  [styles.header__week__item__selected]: moment
                    .utc(day.date)
                    .isSame(selectedDate.date, 'day'),
                  [styles.header__week__item__event_marker]: day.isEvent,
                });
                if (moment.utc(day.date).isSame(currentDate, 'day')) {
                  return (
                    <span
                      className={weekItemClassName}
                      key={day.date}
                      onClick={() => {
                        setSelectedDate(day);
                      }}
                      role='button'
                    >
                      Today
                    </span>
                  );
                }
                return (
                  <span
                    className={weekItemClassName}
                    key={day.date}
                    onClick={() => {
                      setSelectedDate(day);
                    }}
                    role='button'
                  >
                    {moment.utc(day.date).format('D')}
                  </span>
                );
              })}
            </div>
            <div className={styles.header__month}>{moment.utc(currentDate).format('MMM YYYY')}</div>
          </div>
          {eventsData.loading ? (
            <Loader />
          ) : (
            <>
              {isMoreThanOneEvent ? (
                <Slider>
                  {selectedDayEventsList?.map((event: any) => {
                    return (
                      <WidgetItem
                        date={event?.startAt}
                        id={event?.id}
                        img={event?.image}
                        key={event?.id}
                        title={event?.name}
                      />
                    );
                  })}
                </Slider>
              ) : (
                <>
                  {selectedDayEventsList?.[0] && (
                    <WidgetItem
                      date={selectedDayEventsList?.[0]?.startAt}
                      id={selectedDayEventsList?.[0]?.id}
                      img={selectedDayEventsList?.[0]?.image}
                      key={selectedDayEventsList?.[0]?.id}
                      title={selectedDayEventsList?.[0]?.name}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default memo(CalendarWidget);
