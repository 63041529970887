import { useEffect, useState } from 'react';

import { BlockWrapper } from 'components/layouts';
import { PayoutsTable } from 'components/views';
import { useAppDispatch } from 'hooks';
import { payoutsActions } from 'store/payoutsSlice';

import styles from './Payouts.module.scss';

const Payouts: React.FC = () => {
  const dispatch = useAppDispatch();

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  useEffect(() => {
    dispatch(payoutsActions.getPayouts({ skip, take, sort: '', order: 'DESC' }));
  }, [dispatch, skip, take]);

  return (
    <div style={{ width: '90%' }}>
      <BlockWrapper title='Payouts'>
        <div className={styles.wrapper}>
          <PayoutsTable setSkip={setSkip} setTake={setTake} skip={skip} take={take} />
        </div>
      </BlockWrapper>
    </div>
  );
};

export default Payouts;
