import { client } from 'api';

export const activateEmailRequest = (code: any) => client.post('/auth/activate-email', code);
export const setPasswordRequest = (credentials: any) =>
  client.put('affiliate/auth/password', credentials);

export const resetPasswordRequest = (email: any) => client.post('/auth/reset-password', email);

export const signInRequest = (credentials: any) =>
  client.post('/affiliate/auth/login', credentials);
export const verifyTwoFARequest = (otp: string) => client.post('auth/authenticate-2fa', { otp });

export const setupTWoFARequest = () => client.get('/users/2fa-setup');

export const enableTwoFARequest = ({ deviceToken, otp }: any) =>
  client.put('/users/2fa-enable', { deviceToken, otp });
export const disableTwoFARequest = (otp: any) => client.put('/users/2fa-disable', { otp });

// set new password api
export const activeUpdatePassRequest = (code: any) =>
  client.put('/affiliate/auth/confirm-reset-password', code);

export const setNewPasswordRequest = (credentials: any) =>
  client.put('/affiliate/auth/reset-password', credentials);

// forgot pass
export const forgotPasswordRequest = (credentials: any) =>
  client.post('/affiliate/auth/send-forgot-password', credentials);

// public url check if Maintenance
export const getFAQAnswers = () => {
  return client.get('/faqs/DE?skip=0&take=10&sort=rank&order=ASC');
};
