import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';

import { BlockWrapper } from 'components/layouts';
import { Button, Copyright } from 'components/shared';
import { useAppDispatch } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { authActions } from 'store/authSlice';
import { RoutesData } from 'types';

import styles from './TwoFALogin.module.scss';

function TwoFALogin({ hideTFA }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState('');
  const [signInError, showSignInError] = useState(false);

  const handleVerify = useCallback(async () => {
    if (value) {
      const code = { otp: value };
      try {
        const response: any = await dispatch(authActions.verifyTwoFA(code)).unwrap();
        if (response?.accessToken) {
          navigate(RoutesData.Dashboard);
          BrowserStorageService.remove(BrowserStorageKeys.AccessToken, { session: true });
        }
      } catch (error) {
        showSignInError(true);
      }
    }
  }, [dispatch, navigate, value]);

  const classNamesInput = useMemo(
    () => ({
      character: signInError ? styles.input_error : styles.simple_input,
      characterSelected: styles.simple_input__focus,
    }),
    [signInError],
  );

  return (
    <div className={styles.container}>
      <BlockWrapper marginBottom='0'>
        <div className={styles.wrapper}>
          <div className={styles.wrapper__title}>Two-Factor Authentication</div>
          <div className={styles.wrapper__subtitle}>
            Enter the code generated by your authenticator app.
          </div>
          <VerificationInput
            autoFocus
            classNames={classNamesInput}
            length={6}
            onChange={(value: string) => {
              setValue(value);
              showSignInError(false);
            }}
            placeholder=''
            validChars={'0-9'}
            value={value}
          />
          <div className={styles.error}>{signInError ? 'Incorrect authentication code.' : ''}</div>
          <Button title='Verify' callback={handleVerify} />
          <span role='button' onClick={() => hideTFA(false)} className={styles.go_to}>
            Back to login page
          </span>
        </div>
      </BlockWrapper>
      <Copyright />
    </div>
  );
}

export default TwoFALogin;
