import { client } from 'api';

export const getReinvestedTokensSumRequest = (params: any) =>
  client.get('/affiliate/dashboard/reinvested-tokens-sum', { params });

export const getReinvestedTokensRequest = (params: any) =>
  client.get('/affiliate/transactions/reinvested-tokens', { params });

export const getCommissionsSumRequest = () => client.get('/affiliate/dashboard/commissions-sum');

export const getSalesChartRequest = () => client.get('/affiliate/dashboard/sales-chart');

export const getEventsListRequest = (params: any) => client.get('/affiliate/events', { params });
export const getEventRequest = (id: string) => client.get(`/affiliate/events/${id}`);

export const getNewsListRequest = (params: any) => client.get('/affiliate/news', { params });
export const getNewsRequest = (id: string) => client.get(`/affiliate/news/${id}`);
