import {
  Calendar,
  ConfirmRegistration,
  CustomerInfo,
  EventDetails,
  News,
  NewsDetails,
  ReinvestedTokens,
  SetNewPassword,
  TwoFactorAuthentication,
} from 'components/views';
import {
  Commissions,
  Customers,
  Dashboard,
  ForgotPassword,
  Login,
  Maintenance,
  NestedPartners,
  Payouts,
  Profile,
} from 'containers';

import { RoutesProps, RoutesData } from '../types';

const routes: RoutesProps[] = [
  {
    component: <Commissions />,
    isProtected: true,
    path: RoutesData.Commissions,
  },
  {
    component: <Customers />,
    exact: true,
    isProtected: true,
    path: RoutesData.Customers,
  },
  {
    component: <CustomerInfo />,
    exact: true,
    isProtected: true,
    path: `${RoutesData.Customers}/:id`,
  },
  {
    component: <Dashboard />,
    exact: true,
    isProtected: true,
    path: RoutesData.Dashboard,
  },
  {
    component: <Calendar />,
    isProtected: true,
    path: `${RoutesData.Dashboard}/calendar`,
  },
  {
    component: <EventDetails />,
    exact: true,
    isProtected: true,
    path: `${RoutesData.Dashboard}/calendar/:id`,
  },
  {
    path: `${RoutesData.Dashboard}/news`,
    isProtected: true,
    component: <News />,
  },
  {
    component: <NewsDetails />,
    exact: true,
    isProtected: true,
    path: `${RoutesData.Dashboard}/news/:id`,
  },
  {
    component: <Login />,
    exact: true,
    isProtected: false,
    path: RoutesData.Login,
    withSidebar: false,
  },
  {
    component: <ForgotPassword />,
    exact: true,
    isProtected: false,
    path: `${RoutesData.Login}/forgot-password`,
    withSidebar: false,
  },
  {
    component: <ConfirmRegistration />,
    isProtected: false,
    path: `/auth/activate`,
    withSidebar: false,
  },
  {
    component: <SetNewPassword />,
    isProtected: false,
    path: `/auth/reset-password`,
    withSidebar: false,
  },
  {
    component: <Payouts />,
    isProtected: true,
    path: RoutesData.Payouts,
  },
  {
    component: <Profile />,
    isProtected: true,
    path: RoutesData.Profile,
  },
  {
    component: <ReinvestedTokens />,
    isProtected: true,
    path: RoutesData.ReinvestedTokens,
  },
  {
    component: <TwoFactorAuthentication />,
    isProtected: true,
    path: RoutesData.TwoFactorAuthentication,
  },
  {
    component: <NestedPartners />,
    isProtected: true,
    path: RoutesData.NestedPartners,
  },
  {
    component: <Maintenance />,
    isProtected: false,
    path: RoutesData.Maintenance,
    withSidebar: false,
  },
];

export default routes;
