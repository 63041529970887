import Slider from 'react-slick';

import { ISliderWrapper } from './types';
import CustomArrow from './CustomArrow';

const defaultSettings = {
  arrows: true,
  infinite: false,
  initialSlide: 0,
  speed: 500,
  slidesToShow: 1.8,
  slidesToScroll: 1,
  nextArrow: <CustomArrow type='next' />,
  prevArrow: <CustomArrow type='prev' />,
};

function SliderWrapper({
  children,
  settings = defaultSettings,
  topPosition = '18%',
}: ISliderWrapper) {
  const updatedSettingsWithArrowTopPosition = {
    ...settings,
    nextArrow: <CustomArrow type='next' top={topPosition} />,
    prevArrow: <CustomArrow type='prev' top={topPosition} />,
  };

  return <Slider {...updatedSettingsWithArrowTopPosition}>{children}</Slider>;
}

export default SliderWrapper;
