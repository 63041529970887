type DateRangeItem = { id: string; name: string };

export enum DatesType {
  dates = 'dd/MMMM/yyyy',
  month = 'MMM/yyyy',
  quarter = 'QQQ/yyyy',
  year = 'yyyy',
}

const dateRangeTabs: DateRangeItem[] = [
  {
    id: DatesType.dates,
    name: 'Dates',
  },
  {
    id: DatesType.month,
    name: 'Month',
  },
  {
    id: DatesType.quarter,
    name: 'Quarter',
  },
  {
    id: DatesType.year,
    name: 'Year',
  },
];

export default dateRangeTabs;
