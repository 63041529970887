export const decimals: any = {
  BTC: '100000000',
  ETH: '1000000000000000000',
  XRP: '1000000',
  USDC: '1000000',
  ADA: '1000000',
  XAB: '1000000',
  BONUS: '10000',
  LOCKED: '1000000',
  HT: '1000000000000000000',
  PAXG: '1000000000000000000',
  BNB: '1000000000000000000',
  MATIC: '1000000000000000000',
  CURRENCY: '10000',
  EUR: '10000',
  USD: '10000',
};
