export enum RoleType {
  ADMIN = 'ADMIN',
}

export enum StatusType {
  BLOCKED = 'BLOCKED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED',
}

export interface IApiParams {
  skip: number | string;
  take: number | string;
  sort: string;
  order: string;
  dateFrom?: string;
  dateTo?: string;
  filter?: any;
  globalSearch?: string;
}
