import { client } from 'api';

export const getNestedPartnersRequest = (params: any) =>
  client.get('/affiliate/referees', { params });

export const getNestedPartnerByIdRequest = (id: string, params: any) =>
  client.get(`affiliate/referees/${id}`, { params });

export const getNestedPartnersStatisticRequest = (params?: any) =>
  client.get(`affiliate/refereesStatistics`, { params });
