export type RoutesProps = {
  component: JSX.Element;
  exact?: boolean;
  isProtected?: boolean;
  path: string;
  to?: string;
  withSidebar?: boolean;
};

export enum RoutesData {
  Commissions = '/commissions',
  Customers = '/customers',
  Dashboard = '/dashboard',
  Default = '*',
  Error = '*',
  Login = '/login',
  NestedPartners = '/nested-partners',
  Payouts = '/payouts',
  Profile = '/profile',
  ReinvestedTokens = '/dashboard/reinvested-tokens',
  TwoFactorAuthentication = '/profile/two-factor-auth',
  Maintenance = '/maintenance',
}

export type ParamsWithId = {
  id: string;
};
