import { useEffect } from 'react';
import moment from 'moment';

import { BlockWrapper } from 'components/layouts';
import { NewsItem, Loader } from 'components/shared';
import { useAppDispatch, useAppSelector } from 'hooks';
import { dashboardActions, dashboardSelectors } from 'store/dashboardSlice';
import { RoutesData } from 'types';

import styles from './News.module.scss';

const News = () => {
  const dispatch = useAppDispatch();
  const newsData = useAppSelector(dashboardSelectors.selectNewsListData);

  useEffect(() => {
    dispatch(
      dashboardActions.getNewsList({
        skip: 0,
        take: 10,
        sort: 'publishAt',
        order: 'DESC',
      }),
    );
  }, [dispatch]);

  return (
    <div style={{ width: '90%' }}>
      <BlockWrapper
        backTo='Back to dashboard'
        backToPath={RoutesData.Dashboard}
        marginBottom='0px'
        title='News feed'
      >
        <div className={styles.wrapper}>
          {newsData?.loading ? (
            <Loader />
          ) : (
            newsData?.data?.map((news: any) => {
              return (
                <NewsItem
                  key={news.id}
                  date={moment(news?.publishAt).format('D MMM YYYY')}
                  details={news?.body}
                  id={news?.id}
                  img={news?.image}
                  title={news?.name}
                />
              );
            })
          )}
        </div>
      </BlockWrapper>
    </div>
  );
};

export default News;
