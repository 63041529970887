import { useEffect } from 'react';
import TableBody from '@mui/material/TableBody';

import { EmptyData, Loader, Pagination, Table } from 'components/shared';
import { customerBonusTable } from 'constants/index';
import { useAppDispatch, useAppSelector } from 'hooks';
import { customersActions, customersSelectors } from 'store/customersSlice';

import CustomerBonusRow from './CustomerBonusRow';
import styles from './CustomerBonusTable.module.scss';

const CustomerBonusTable = ({
  dateFrom,
  dateTo,
  id,
  select,
  skip,
  take,
  setSkip,
  setTake,
}: any) => {
  const dispatch = useAppDispatch();

  const customersBonusData = useAppSelector(customersSelectors.selectCustomerBonusTransactions);
  const totalCount = customersBonusData.totalCount || 1;
  const list = customersBonusData.list;
  const loading = customersBonusData.loading;

  const handlePage = (_event: unknown, newPage: number) => {
    setSkip(newPage * take);
  };
  const handleRows = (event: any) => {
    setTake(event.target.value);
    setSkip(0);
  };

  useEffect(() => {
    if (id) {
      dispatch(
        customersActions.getCustomersBonus({
          userId: id,
          skip,
          take,
          sort: 'createdAt',
          order: 'DESC',
          dateFrom,
          dateTo,
          ...(select !== 'ALL' && {
            filter: `{ "category": "${select}" }`,
          }),
        }),
      );
    }
  }, [dateFrom, dateTo, dispatch, id, select, skip, take]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.wrapper}>
          <Table headerArray={customerBonusTable}>
            <TableBody>
              {list?.map((row: any) => (
                <CustomerBonusRow row={row} key={row.id} />
              ))}
            </TableBody>
          </Table>
          {!customersBonusData.totalCount && <EmptyData />}
        </div>
      )}

      <Pagination
        handleChangePage={handlePage}
        handleChangeRowsPerPage={handleRows}
        currentPage={skip / take || 0}
        rowsPerPage={take}
        totalCount={totalCount}
      />
    </>
  );
};
export default CustomerBonusTable;
