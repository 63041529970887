import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';

import { Slice } from 'types';
import { extraReducers } from 'utils';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import * as authThunks from './thunks';
import { AuthSliceState, UpdateAccessTokenAction } from './types';

const internalInitialState: AuthSliceState = {
  accessToken:
    BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
    BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true }) ||
    '',
  deviceToken: BrowserStorageService.get(BrowserStorageKeys.DeviceToken),
  error: null,
  loading: false,
  role: '',
  twoFactorAuthEnabled: false,
};

const authSlice = createSlice({
  name: Slice.Auth,
  initialState: internalInitialState,
  reducers: {
    updateAccessToken(state, action: PayloadAction<UpdateAccessTokenAction>) {
      state.accessToken = action.payload.token;
    },
    reset: () => internalInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(authThunks.userInfoRequest.fulfilled, (state, action) => {
      state.personalInfo = action.payload.personalInfo;
      state.loading = false;
      state.error = null;
      state.role = action.payload.personalInfo.role;
    });

    builder.addCase(authThunks.enableTwoFA.fulfilled, (state, action) => {
      state.accessToken = action.payload.token;
    });

    builder.addCase(authThunks.signOut.fulfilled, () => ({
      ...internalInitialState,
      accessToken: '',
      loading: false,
      error: null,
    }));

    builder.addMatcher(
      isAnyOf(
        authThunks.signIn.pending,
        authThunks.userInfoRequest.pending,
        authThunks.signOut.pending,
      ),
      extraReducers.pendingReducer,
    );

    builder.addMatcher(
      isAnyOf(
        authThunks.signIn.rejected,
        authThunks.userInfoRequest.rejected,
        authThunks.signOut.rejected,
      ),
      extraReducers.errorReducer,
    );
  },
});

const { reducer, actions } = authSlice;

export const authActions = {
  ...actions,
  ...authThunks,
};

export * as authSelectors from './selectors';

export default reducer;
