import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { Logo, Logout } from 'assets/icons';
import { sidebarNavigation } from 'constants/index';
import { useAppDispatch } from 'hooks';
import { authActions } from 'store/authSlice';
import { nestedPartnersActions } from 'store/nestedSlice';
import { RoutesData } from 'types';

import styles from './Sidebar.module.scss';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [showNestedPartners, setShowNestedPartners] = useState(false);

  const renderList = sidebarNavigation.map(({ id, Icon, text, linkTo, admit }) => {
    if (!showNestedPartners && text === 'Nested Partners') return null;
    if (admit === 'ALL') {
      return (
        <Link
          className={classNames(styles.list, {
            [styles.list__open]: location?.pathname.includes(linkTo),
          })}
          key={id}
          to={linkTo}
        >
          <div className={styles.list__item}>
            <div
              className={classNames(styles.list__icon, {
                [styles.list__icon__open]: location?.pathname.includes(linkTo),
              })}
            >
              <Icon />
            </div>
            <div
              className={classNames(styles.list__text, {
                [styles.list__text__open]: location?.pathname.includes(linkTo),
              })}
            >
              {text}
            </div>
          </div>
        </Link>
      );
    }
  });

  function handleLogOut(): void {
    dispatch(authActions.signOut());
  }

  const getPartners = useCallback(async () => {
    const data = await dispatch(
      nestedPartnersActions.getNestedPartners({
        globalSearch: '',
      }),
    );
    if (data?.payload?.length) {
      setShowNestedPartners(true);
    }
  }, [dispatch]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__logo}>
        <Logo />
      </div>
      <div className={styles.wrapper__content}>
        <div>{renderList}</div>
        <div
          className={styles.list__item}
          onClick={handleLogOut}
          role='button'
          style={{ cursor: 'pointer' }}
        >
          <div className={styles.list__icon}>
            <Logout />
          </div>
          <Link className={styles.list__text} to={RoutesData.Login}>
            Log out
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
