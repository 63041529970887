import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { CopyIcon, Key, Lock } from 'assets/icons';
import { BlockWrapper } from 'components/layouts';
import { Button, Toast } from 'components/shared';
import { useAppDispatch, useAppSelector } from 'hooks';
import { profileActions, profileSelectors } from 'store/profileSlice';
import { RoutesData } from 'types';
import { checkIfValueIsEmpty } from 'utils';

import styles from './Profile.module.scss';

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(profileSelectors.selectProfileData);
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [success, showSuccess] = useState(false);

  useEffect(() => {
    dispatch(profileActions.getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setTimeout(() => showSuccess(false), 3000);
    }
  }, [success]);

  const handleChangePasswrod = useCallback(async () => {
    const response = await dispatch(profileActions.updatePassword()).unwrap();
    if (response.status === 201) {
      setPasswordStatus(true);
    }
  }, [dispatch]);

  const toastSuccessClassNames = classNames(styles.toaster, {
    [styles.toaster__success]: success,
  });

  return (
    <div style={{ minHeight: '100vh', width: '90%' }}>
      <BlockWrapper title='Profile'>
        <div className={styles.wrapper}>
          <div className={styles.wrapper__profile_title}>
            <div className={styles.title}>
              {checkIfValueIsEmpty(profile?.profile?.firstName)}{' '}
              {checkIfValueIsEmpty(profile?.profile?.lastName)}
            </div>
            <div className={styles.email}>{checkIfValueIsEmpty(profile?.email)}</div>
          </div>
          <div className={styles.wrapper__profile_info}>
            <div className={styles.wrapper__profile_info__item}>
              <span className={styles.title}>Manager</span>
              <span className={styles.details}>
                {checkIfValueIsEmpty(profile?.affiliateProfile?.manager)}
              </span>
            </div>
            <div className={styles.wrapper__profile_info__compex_item}>
              <div className={styles.wrapper__profile_info__item}>
                <span className={styles.title}>Phone Number</span>
                <span className={styles.details}>
                  {checkIfValueIsEmpty(profile?.profile?.phone)}
                </span>
              </div>
              <div className={styles.wrapper__profile_info__item}>
                <span className={styles.title}>Tax Number</span>
                <span className={styles.details}>
                  {checkIfValueIsEmpty(profile?.affiliateProfile?.taxNumber)}
                </span>
              </div>
            </div>
            <div className={styles.wrapper__profile_info__item}>
              <span className={styles.title}>Address</span>
              <span className={styles.details}>
                {checkIfValueIsEmpty(profile?.affiliateProfile?.address)}
              </span>
            </div>
            {profile?.affiliateProfile?.referralCode ? (
              <div className={styles.wrapper__profile_info__item}>
                <span className={styles.title}>Referral Code</span>
                <div className={styles.referralCode}>
                  <span className={styles.details}>
                    {checkIfValueIsEmpty(profile?.affiliateProfile?.referralCode)}
                  </span>
                  <div
                    className={styles.copy_btn}
                    onClick={async () => {
                      if ('clipboard' in navigator) {
                        await navigator.clipboard.writeText(
                          profile?.affiliateProfile?.referralCode,
                        );
                        showSuccess(true);
                      }
                    }}
                  >
                    <span>Copy</span>
                    <CopyIcon />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles.wrapper__settings}>
            <div className={styles.title}>Security settings</div>
            <div className={styles.btn_container}>
              <Button
                disabled={passwordStatus}
                callback={handleChangePasswrod}
                Icon={Key}
                title='Change Password'
              />
              <Link to={RoutesData.TwoFactorAuthentication}>
                <Button Icon={Lock} title='Two-Factor Authentication' />
              </Link>
            </div>
            {passwordStatus ? (
              <div className={styles.password_status}>
                A request to change your password has been sent to your email
              </div>
            ) : null}
          </div>
        </div>
        <Toast
          showToast={success}
          message={`Referral Code copied`}
          toastClassNames={toastSuccessClassNames}
        />
      </BlockWrapper>
    </div>
  );
};

export default Profile;
