import { HeadCell } from 'types';

const customerXABTable: HeadCell[] = [
  {
    id: 'category',
    value: 'category',
    label: 'Category',
  },
  {
    id: 'amount',
    value: 'amount',
    label: 'Amount, XAB',
  },
  {
    id: 'date',
    value: 'date',
    label: 'Date and time',
  },
];

export default customerXABTable;
