import { createAsyncThunk } from '@reduxjs/toolkit';

import { nestedApi } from 'api';
import { Slice } from 'types';

export const getNestedPartners = createAsyncThunk(
  `${Slice.NestedPartners}/nested-partners`,
  async (
    credentials: {
      globalSearch: string;
    },
    thunkAPI,
  ) => {
    const { ...restCredentials } = credentials;

    try {
      const response = await nestedApi.getNestedPartnersRequest(restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const getNestedPartnerById = createAsyncThunk(
  `${Slice.NestedPartners}/by-id`,
  async (
    credentials: {
      id: string;
      skip: number;
      take: number;
      sort: string;
      order: string;
      globalSearch?: string;
      dateFrom?: string;
      dateTo?: string;
    },
    thunkAPI,
  ) => {
    const { id, ...restCredentials } = credentials;
    try {
      const response = await nestedApi.getNestedPartnerByIdRequest(id, restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const getNestedPartnersStatistic = createAsyncThunk(
  `${Slice.NestedPartners}/statistic-summary`,
  async (
    credentials: {
      dateFrom?: string;
      dateTo?: string;
    },
    thunkAPI,
  ) => {
    const { ...restCredentials } = credentials;
    try {
      const response = await nestedApi.getNestedPartnersStatisticRequest(restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);
