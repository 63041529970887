import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { BlockWrapper } from 'components/layouts';
import { Button, Input, Toast } from 'components/shared';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RoutesData } from 'types';
import { authActions, authSelectors } from 'store/authSlice';

import { ERROR_CODES } from '../../../constants';

import styles from './TwoFactorAuthentication.module.scss';
import TFAoff from './components/TFAoff';
import TFAon from './components/TFAon';

function TwoFactorAuthentication() {
  const dispatch = useAppDispatch();
  const personalInfo = useAppSelector(authSelectors.selectPersonalInfo);
  const deviceToken = useAppSelector(authSelectors.selectDeviceToken);

  const [error, showError] = useState(false);
  const [success, showSuccess] = useState(false);
  const [inputError, setInputError] = useState<null | string>(null);
  const [secretCode, setSecretCode] = useState('');
  const [setupKey, setSetupKey] = useState('');
  const [qrUrl, setQrUrl] = useState('');

  const setupTwoFA = useCallback(async () => {
    try {
      const response = await dispatch(authActions.setupTwoFA()).unwrap();
      setSetupKey(response?.secret);
      setQrUrl(response?.otpauthUrl);
    } catch (error) {
      /* empty */
    }
  }, [dispatch]);

  useEffect(() => {
    if (!personalInfo?.settings?.twoFactorAuthEnabled) {
      setupTwoFA();
    }
  }, [setupTwoFA, personalInfo?.settings?.twoFactorAuthEnabled]);

  const handleSecretCode = useCallback((event: any) => {
    setInputError(null);
    setSecretCode(event.target.value);
  }, []);

  const handleTwoFARequest = useCallback(
    async (request: any, secretCode: string) => {
      try {
        const code = {
          deviceToken,
          otp: secretCode,
        };
        const response = await dispatch(request({ ...code })).unwrap();
        if (response.status === ERROR_CODES[403]) {
          showError(true);
        } else if (response.status === 200) {
          showSuccess(true);
          dispatch(authActions.userInfoRequest({}));
          setSecretCode('');
        }
      } catch (error) {
        showError(true);
      }
    },
    [deviceToken, dispatch],
  );

  const handleTwoFA = useCallback(async () => {
    if (!secretCode || secretCode?.length !== 6) {
      setInputError('* Secret code must contain six symbols');
      return;
    }

    try {
      if (personalInfo?.settings?.twoFactorAuthEnabled) {
        await handleTwoFARequest(authActions.disableTwoFA, secretCode);
      } else {
        await handleTwoFARequest(authActions.enableTwoFA, secretCode);
      }
    } catch (error) {
      showError(true);
    }
  }, [handleTwoFARequest, personalInfo?.settings?.twoFactorAuthEnabled, secretCode]);

  useEffect(() => {
    if (error) {
      setTimeout(() => showError(false), 7000);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      setTimeout(() => showSuccess(false), 5000);
    }
  }, [success]);

  const toastErrorClassNames = classNames(styles.toaster, {
    [styles.toaster__error]: error,
    [styles.toaster__success]: success,
  });

  return (
    <div style={{ height: '100vh', width: '90%' }}>
      <BlockWrapper backTo='Back to profile' backToPath={RoutesData.Profile} marginBottom='0px'>
        <div className={styles.wrapper}>
          <div className={styles.wrapper__header}>
            <div className={styles.title}>Two-Factor Authentication</div>
            <div className={styles.status_block}>
              <div className={styles.status_block__title}>2FA Status:</div>
              <span
                className={styles.status_block__mode}
                style={
                  personalInfo?.settings?.twoFactorAuthEnabled
                    ? { background: 'rgba(49, 188, 131, 0.07)', color: '#31BC83' }
                    : { background: 'rgba(235, 87, 70, 0.07)', color: '#EB5746' }
                }
              >
                {personalInfo?.settings?.twoFactorAuthEnabled ? 'Enabled' : 'Disabled'}
              </span>
            </div>
          </div>
          <div className={styles.wrapper__content}>
            {personalInfo?.settings?.twoFactorAuthEnabled ? (
              <TFAoff />
            ) : (
              <TFAon qrUrl={qrUrl} setupKey={setupKey} />
            )}
          </div>
          <div
            className={styles.wrapper__input_block}
            style={{ alignItems: inputError ? 'center' : 'flex-end' }}
          >
            <div style={{ width: '270px' }}>
              <Input
                error={inputError}
                label='Secret code'
                onChange={handleSecretCode}
                placeHolder='Enter secret code'
                value={secretCode}
              />
            </div>
            <div style={{ width: 'auto' }}>
              <Button title='Confirm' callback={handleTwoFA} />
            </div>
          </div>
        </div>
      </BlockWrapper>
      <Toast
        showToast={error}
        message={`* Wrong authenticator OTP`}
        toastClassNames={toastErrorClassNames}
      />
      <Toast
        showToast={success}
        message={`* 2FA setting has been updated successfully`}
        toastClassNames={toastErrorClassNames}
      />
    </div>
  );
}
export default TwoFactorAuthentication;
