import { createSlice } from '@reduxjs/toolkit';
import assign from 'lodash/assign';

import { Slice } from 'types';

import { CustomersSliceState } from './types';
import * as customersThunks from './thunks';

const internalInitialState: CustomersSliceState = {
  customersData: null,
  customersDataError: null,
  customersDataLoading: false,

  customersBonusTransactionsData: {
    error: null,
    list: [],
    loading: false,
    totalCount: 0,
  },
  customersXABTransactionsData: {
    error: null,
    list: [],
    loading: false,
    totalCount: 0,
  },
  currentCustomer: null,
};

const customersSlice = createSlice({
  name: Slice.Customers,
  initialState: internalInitialState,
  reducers: {
    reset: () => internalInitialState,
  },
  extraReducers(builder) {
    builder.addCase(customersThunks.setCurrentCustomer, (state, actions) => {
      state.currentCustomer = actions.payload;
    });

    // async action
    builder
      .addCase(customersThunks.getCustomers.fulfilled, (state, actions) => {
        state.customersData = actions.payload;
        state.customersDataError = null;
        state.customersDataLoading = false;
      })
      .addCase(customersThunks.getCustomers.pending, (state) => {
        assign(state, {
          customersData: [],
          customersDataError: null,
          customersDataLoading: true,
        });
      })
      .addCase(customersThunks.getCustomers.rejected, (state, { payload }) => {
        assign(state, {
          customersData: [],
          customersDataError: payload,
          customersDataLoading: false,
        });
      });

    builder
      .addCase(customersThunks.getCustomersXAB.fulfilled, (state, { payload }) => {
        assign(state.customersXABTransactionsData, {
          error: null,
          list: payload.list,
          loading: false,
          totalCount: Number(payload.totalCount),
        });
      })
      .addCase(customersThunks.getCustomersXAB.pending, (state) => {
        assign(state.customersXABTransactionsData, {
          error: null,
          list: [],
          loading: true,
          totalCount: 0,
        });
      })
      .addCase(customersThunks.getCustomersXAB.rejected, (state, { payload }) => {
        assign(state.customersXABTransactionsData, {
          error: payload,
          list: [],
          loading: false,
          totalCount: 0,
        });
      });

    builder
      .addCase(customersThunks.getCustomersBonus.fulfilled, (state, { payload }) => {
        assign(state.customersBonusTransactionsData, {
          error: null,
          list: payload.list,
          loading: false,
          totalCount: Number(payload.totalCount),
        });
      })
      .addCase(customersThunks.getCustomersBonus.pending, (state) => {
        assign(state.customersBonusTransactionsData, {
          error: null,
          list: [],
          loading: true,
          totalCount: 0,
        });
      })
      .addCase(customersThunks.getCustomersBonus.rejected, (state, { payload }) => {
        assign(state.customersBonusTransactionsData, {
          error: payload,
          list: [],
          loading: false,
          totalCount: 0,
        });
      });
  },
});

const { reducer, actions } = customersSlice;

export const customersActions = {
  ...actions,
  ...customersThunks,
};

export * as customersSelectors from './selectors';

export default reducer;
