import { createAsyncThunk } from '@reduxjs/toolkit';

import { payoutsApi } from 'api';
import { Slice } from 'types';

export const getPayouts = createAsyncThunk(
  `${Slice.Payouts}/payouts`,
  async (
    credentials: {
      skip: number;
      take: number;
      sort: string;
      order: string;
    },
    thunkAPI,
  ) => {
    const { ...restCredentials } = credentials;

    try {
      const response = await payoutsApi.getPayoutsRequest(restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);
