import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { PayoutsSliceState } from './types';

const selectPayuots = (state: RootState): PayoutsSliceState => state.payouts;

export const selectPayoutsData = createSelector(selectPayuots, (payouts) => payouts.payoutsData);

export const selectPayoutsLoading = createSelector(
  selectPayuots,
  (payouts) => payouts.payoutsLoading,
);
