import { useRef, useState } from 'react';
import classNames from 'classnames';

import { SelectArrow } from 'assets/icons';
import { useOnClickOutside } from 'hooks';

import { ISelect } from './types';
import styles from './Select.module.scss';

const Select = ({ id, name, options, callback, value }: ISelect) => {
  const customRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleChange = (value: string) => {
    callback(value);
  };

  const handleCloseOptions = () => setShowMenu(false);

  useOnClickOutside(customRef, handleCloseOptions);

  const iconClassName = classNames(styles.icon, {
    [styles.icon__open]: showMenu,
  });

  const label = options?.filter((option: any) => option.value === value)[0]?.label || '';

  return (
    <div
      ref={customRef}
      className={styles.container}
      id={id || name}
      onClick={() => setShowMenu(!showMenu)}
    >
      <div className={styles.container__title}>
        <span className={styles.placeholder}>Category</span>
        <span style={{ fontWeight: '600' }}>{label}</span>
        <SelectArrow className={iconClassName} />
      </div>

      {showMenu ? (
        <div className={styles.container__options}>
          {options.map((option: any) => (
            <span
              className={styles.option}
              key={option.value}
              onClick={() => handleChange(option.value)}
              style={{
                color: value === option.value ? 'rgba(208, 102, 67, 1)' : '',
                background: value === option.value ? 'rgba(247, 248, 248, 1)' : '',
              }}
            >
              {option.label}
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Select;
