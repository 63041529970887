import { createSlice } from '@reduxjs/toolkit';
import assign from 'lodash/assign';

import { Slice } from 'types';

import { CommissionsSliceState } from './types';
import * as commissionsThunks from './thunks';

const internalInitialState: CommissionsSliceState = {
  commissionsData: {
    error: null,
    list: [],
    loading: false,
    totalCount: 0,
  },
};

const commissionsSlice = createSlice({
  name: Slice.Commissions,
  initialState: internalInitialState,
  reducers: {
    reset: () => internalInitialState,
  },
  extraReducers(builder) {
    builder
      .addCase(commissionsThunks.getTransactions.fulfilled, (state, actions) => {
        assign(state.commissionsData, {
          list: actions.payload.list,
          error: null,
          loading: false,
          totalCount: actions.payload.totalCount || 0,
        });
      })
      .addCase(commissionsThunks.getTransactions.pending, (state) => {
        assign(state.commissionsData, {
          list: [],
          error: null,
          loading: true,
          totalCount: 0,
        });
      })
      .addCase(commissionsThunks.getTransactions.rejected, (state, actions) => {
        assign(state.commissionsData, {
          list: [],
          error: actions.payload,
          loading: false,
          totalCount: 0,
        });
      });
  },
});

const { reducer, actions } = commissionsSlice;

export const commissionsActions = {
  ...actions,
  ...commissionsThunks,
};

export * as commissionsSelectors from './selectors';

export default reducer;
