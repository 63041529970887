import { ChangeEvent } from 'react';
import TableBody from '@mui/material/TableBody';

import { EmptyData, Loader, Pagination, ScrollWrapper, Table } from 'components/shared';
import { commissionsTable } from 'constants/index';
import { useAppSelector } from 'hooks';
import { commissionsSelectors } from 'store/commissionsSlice';
import { ITableTypes } from 'types';

import CommissionRow from './CommissionRow';
import styles from './CommissionsTable.module.scss';

const CommissionsTable = ({ setSkip, setTake, skip, take }: ITableTypes) => {
  const commissionData = useAppSelector(commissionsSelectors.selectCommissionsData);
  const loading = commissionData.loading;
  const totalCount = commissionData.totalCount || 1;

  const handlePage = (_event: unknown, newPage: number) => {
    setSkip(newPage * take);
  };
  const handleRows = (event: ChangeEvent<HTMLInputElement>) => {
    setTake(event.target.value);
    setSkip(0);
  };

  return (
    <div>
      <div>
        <h3 className={styles.table_title}>XAB Purchase transactions</h3>
        {loading ? (
          <Loader />
        ) : (
          <ScrollWrapper>
            <Table headerArray={commissionsTable}>
              <TableBody>
                {commissionData?.list?.map((row: any) => (
                  <CommissionRow row={row} key={row.createdAt} />
                ))}
              </TableBody>
            </Table>
          </ScrollWrapper>
        )}
        {!commissionData.totalCount && !loading && <EmptyData />}
      </div>
      <Pagination
        handleChangePage={handlePage}
        handleChangeRowsPerPage={handleRows}
        currentPage={skip / take || 0}
        rowsPerPage={take}
        totalCount={totalCount}
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
    </div>
  );
};
export default CommissionsTable;
