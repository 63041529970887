import { useCallback, useEffect, useState } from 'react';

import { BlockWrapper } from 'components/layouts';
import { ReinvestedTokensTable } from 'components/views';
import { DatePicker } from 'components/shared';
import { useAppDispatch, useAppSelector } from 'hooks';
import { dashboardActions, dashboardSelectors } from 'store/dashboardSlice';
import { RoutesData } from 'types';
import { calcsDecimals, calculatePeriod } from 'utils';

import styles from './ReinvestedTokens.module.scss';

function ReinvestedTokens() {
  const dispatch = useAppDispatch();
  const reinvestedTokenSum = useAppSelector(dashboardSelectors.selectReinvestedTokensSum);
  const loading = useAppSelector(dashboardSelectors.selectReinvestedTokensSumLoading);
  const value = reinvestedTokenSum
    ? calcsDecimals.toCoin(Number(reinvestedTokenSum), 'XAB').toFixed()
    : '';

  const [dateFrom, setDateFromValue] = useState<string | undefined>('');
  const [dateTo, setDateToValue] = useState<string | undefined>('');
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  useEffect(() => {
    const getTokensData = async () => {
      try {
        await dispatch(
          dashboardActions.getReinvestedTokens({
            skip,
            take,
            sort: 'createdAt',
            order: 'DESC',
            dateFrom,
            dateTo,
          }),
        ).unwrap();
      } catch {
        /** */
      }
    };

    dispatch(
      dashboardActions.getReinvestedTokensSum({
        dateFrom,
        dateTo,
      }),
    );
    getTokensData();
  }, [dateFrom, dateTo, dispatch, skip, take]);

  const handleDate = useCallback((value: any, dateFormat: string, endDate?: any) => {
    const period = calculatePeriod(value, dateFormat, endDate);
    setDateFromValue(period.dateFrom);
    setDateToValue(period.dateTo);
    setSkip(0);
    setTake(10);
  }, []);

  return (
    <div style={{ width: '90%' }}>
      <BlockWrapper backTo='Back to dashboard' backToPath={RoutesData.Dashboard} marginBottom='0px'>
        <div className={styles.wrapper}>
          <div className={styles.wrapper__header_block}>
            <div className={styles.title_block}>
              <h4 className={styles.title_block__title}>Reinvested Tokens</h4>
              <div className={styles.title_block__token_value}>
                <span className={styles.title_block__token_value__value}>
                  {loading ? '' : value}
                </span>
                <span className={styles.title_block__token_value__coin}>XAB</span>
              </div>
            </div>
            <div className={styles.date_range_block}>
              <DatePicker onChangeDate={handleDate} />
            </div>
          </div>
          <div className={styles.wrapper__table_block}>
            <ReinvestedTokensTable setSkip={setSkip} setTake={setTake} skip={skip} take={take} />
          </div>
        </div>
      </BlockWrapper>
    </div>
  );
}

export default ReinvestedTokens;
