import { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import classNames from 'classnames';

import { CopyIcon } from 'assets/icons';
import TFA_info from 'constants/TFAinfo';
import { Toast } from 'components/shared';

import styles from '../TwoFactorAuthentication.module.scss';

function TFAon({ qrUrl, setupKey }: any) {
  const [valueInput, setValueInput] = useState('');
  const [success, showSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setTimeout(() => showSuccess(false), 3000);
    }
  }, [success]);

  useEffect(() => {
    setValueInput(setupKey);
  }, [setupKey]);

  const toastSuccessClassNames = classNames(styles.toaster, {
    [styles.toaster__success]: success,
  });

  return (
    <div className={styles.tfa_on_wrapper}>
      <div className={styles.tfa_off_wrapper__title}>How to enable 2FA:</div>
      {TFA_info.TFA_on_info.map((item: string) => (
        <div key={item} className={styles.tfa_off_wrapper__item}>
          {item}
        </div>
      ))}
      <div className={styles.tfa_off_wrapper__item} style={{ marginTop: '24px' }}>
        Next times when sign in to the Teroxx Exclusive dashboard, you will need to enter your
        password and a code from Google authenticator.
      </div>
      <div>
        <div className={styles.tfa_on_wrapper__key}>
          <div className={styles.tfa_on_wrapper__key__value}>
            <span>Setup key</span>
            <div style={{ display: 'flex', marginTop: '8px' }}>
              <span style={{ fontWeight: '600' }}>{valueInput}</span>
              <div
                className={styles.tfa_on_wrapper__key__copy}
                onClick={async () => {
                  if ('clipboard' in navigator) {
                    await navigator.clipboard.writeText(valueInput);
                    showSuccess(true);
                  }
                }}
              >
                <span style={{ marginLeft: '16px' }}>Copy</span>
                <CopyIcon />
              </div>
            </div>
          </div>
          <div className={styles.tfa_on_wrapper__key__qrcode_wrapper}>
            <span className={styles.title}>QR code</span>
            <QRCodeSVG value={qrUrl} />
          </div>
        </div>
      </div>
      <Toast
        showToast={success}
        message={`* Setup key copied`}
        toastClassNames={toastSuccessClassNames}
      />
    </div>
  );
}
export default TFAon;
