import { HeadCell } from 'types';

const reinvestedTokensTable: HeadCell[] = [
  {
    id: 'email',
    value: 'email',
    label: 'Customer email',
  },
  {
    id: 'amount',
    value: 'amount',
    label: 'Amount, XAB',
  },
  {
    id: 'date',
    value: 'date',
    label: 'Date and time',
  },
];

export default reinvestedTokensTable;
