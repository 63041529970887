import moment from 'moment';

const enumerateDaysBetweenDates = function (startDate: any, endDate: any) {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};

export default enumerateDaysBetweenDates;
