import { createSlice } from '@reduxjs/toolkit';

import { Slice } from 'types';

import { PayoutsSliceState } from './types';
import * as payoutsThunks from './thunks';

const internalInitialState: PayoutsSliceState = {
  payoutsError: null,
  payoutsData: [],
  payoutsLoading: false,
};

const payoutsSlice = createSlice({
  name: Slice.Payouts,
  initialState: internalInitialState,
  reducers: {
    reset: () => internalInitialState,
  },
  extraReducers(builder) {
    builder
      .addCase(payoutsThunks.getPayouts.fulfilled, (state, actions) => {
        state.payoutsData = actions.payload;
        state.payoutsLoading = false;
      })
      .addCase(payoutsThunks.getPayouts.pending, (state) => {
        state.payoutsData = [];
        state.payoutsLoading = true;
      })
      .addCase(payoutsThunks.getPayouts.rejected, (state, actions) => {
        state.payoutsData = [];
        state.payoutsLoading = false;
        state.payoutsError = actions.payload;
      });
  },
});

const { reducer, actions } = payoutsSlice;

export const payoutsActions = {
  ...actions,
  ...payoutsThunks,
};

export * as payoutsSelectors from './selectors';

export default reducer;
