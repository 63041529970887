import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BlockWrapper } from 'components/layouts';
import { DatePicker, Select, Tab } from 'components/shared';
import { CustomerXABTable, CustomerBonusTable } from 'components/views';
import customerTransactionsTabs, { TabType } from 'constants/tabs/customerTransactions';
import selectOptions from 'constants/selectOptions';
import { useAppSelector } from 'hooks';
import { customersSelectors } from 'store/customersSlice';
import { RoutesData } from 'types';
import { calculatePeriod, checkIfValueIsEmpty } from 'utils';

import styles from './CustomerInfo.module.scss';

function CustomerInfo() {
  const params = useParams();

  const customer = useAppSelector(customersSelectors.selectCurrentCustomer);

  const [transActionTab, setTransActionTab] = useState<string>(TabType.xab_transactions);
  const [valueSelect, setValueSelect] = useState<string>('ALL');
  const [dateFrom, setDateFromValue] = useState<string | undefined>('');
  const [dateTo, setDateToValue] = useState<string | undefined>('');
  const [skipBonusTable, setSkipBonusTable] = useState(0);
  const [takeBonusTable, setTakeBonusTable] = useState(10);
  const [skipXABTable, setSkipXABTable] = useState(0);
  const [takeXABTable, setTakeXABTable] = useState(10);

  const handleTabChange = (id: string) => {
    setTransActionTab(id);
    setValueSelect('ALL');
    if (id === TabType.xab_transactions) {
      setSkipXABTable(0);
      setTakeXABTable(10);
    } else {
      setSkipBonusTable(0);
      setTakeBonusTable(10);
    }
  };

  const handleValueSelect = useCallback(
    (value: string) => {
      setValueSelect(value);
      if (transActionTab === TabType.xab_transactions) {
        setSkipXABTable(0);
        setTakeXABTable(10);
      } else {
        setSkipBonusTable(0);
        setTakeBonusTable(10);
      }
    },
    [transActionTab],
  );

  const handleDate = useCallback(
    (value: any, dateFormat: string, endDate?: any) => {
      const period = calculatePeriod(value, dateFormat, endDate);
      setDateFromValue(period.dateFrom);
      setDateToValue(period.dateTo);
      if (transActionTab === TabType.xab_transactions) {
        setSkipXABTable(0);
        setTakeXABTable(10);
      } else {
        setSkipBonusTable(0);
        setTakeBonusTable(10);
      }
    },
    [transActionTab],
  );

  return (
    <div style={{ width: '90%' }}>
      <BlockWrapper
        backTo='Back to customers'
        backToPath={RoutesData.Customers}
        marginBottom='0'
        rightSideElement={
          <div
            className={styles.status}
            style={
              customer?.status === 'ACTIVE'
                ? { background: 'rgba(49, 188, 131, 0.07)', color: '#31BC83' }
                : { background: 'rgba(235, 87, 70, 0.07)', color: '#EB5746' }
            }
          >
            {customer?.status?.toLowerCase()}
          </div>
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.wrapper__profile_title}>
            <div className={styles.wrapper__profile_title__title}>
              <div className={styles.title}>
                {checkIfValueIsEmpty(customer?.firstName)} {checkIfValueIsEmpty(customer?.lastName)}
              </div>
              <div className={styles.email}>{checkIfValueIsEmpty(customer?.email)}</div>
            </div>
            <div className={styles.wrapper__profile_title__datepicker}>
              <DatePicker onChangeDate={handleDate} />
            </div>
          </div>
          <div className={styles.wrapper__table_block}>
            <div className={styles.wrapper__table_block__header}>
              <div className={styles.wrapper__table_block__header__tabs}>
                {customerTransactionsTabs.map(({ id, name }: any) => (
                  <Tab
                    handleChange={handleTabChange}
                    id={id}
                    key={id}
                    name={name}
                    selectedTab={transActionTab}
                  />
                ))}
              </div>
              <div>
                <Select
                  value={valueSelect}
                  callback={handleValueSelect}
                  options={
                    transActionTab === TabType.xab_transactions
                      ? selectOptions.XABOpt
                      : selectOptions.bonusOpt
                  }
                />
              </div>
            </div>
            {transActionTab === TabType.xab_transactions ? (
              <CustomerXABTable
                dateFrom={dateFrom}
                dateTo={dateTo}
                id={params?.id}
                select={valueSelect}
                skip={skipXABTable}
                take={takeXABTable}
                setSkip={setSkipXABTable}
                setTake={setTakeXABTable}
              />
            ) : (
              <CustomerBonusTable
                dateFrom={dateFrom}
                dateTo={dateTo}
                id={params?.id}
                select={valueSelect}
                skip={skipBonusTable}
                take={takeBonusTable}
                setSkip={setSkipBonusTable}
                setTake={setTakeBonusTable}
              />
            )}
          </div>
        </div>
      </BlockWrapper>
    </div>
  );
}

export default CustomerInfo;
