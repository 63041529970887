import { Logo } from 'assets/icons';
import { BlockWrapper } from 'components';

import styles from '../SetNewPassword.module.scss';

interface ILinkError {
  linkErrorMessage: string;
}

const LinkError = ({ linkErrorMessage }: ILinkError) => {
  return (
    <BlockWrapper marginBottom='0'>
      <div className={styles.wrapper}>
        <Logo />
        <div className={styles.wrapper__title_error}>{linkErrorMessage}</div>
        <div className={styles.wrapper__subtitle}>
          To change your password, submit a request from the Teroxx Affiliate profile page or a
          password reset request from the login page.
        </div>
        <div style={{ marginTop: '30px' }}></div>
      </div>
    </BlockWrapper>
  );
};

export default LinkError;
