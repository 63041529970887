import { memo } from 'react';
import classNames from 'classnames';

import { IPartnerItem } from './types';
import styles from './PartnerItem.module.scss';

const PartnerItem = ({ isSelected, name = '' }: IPartnerItem) => {
  const classNameContainer = classNames(styles.container, {
    [styles.container__selected]: isSelected,
  });

  return <div className={classNameContainer}>{name}</div>;
};

export default memo(PartnerItem);
