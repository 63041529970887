import { Suspense, lazy, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { BlockWrapper } from 'components/layouts';
import { Loader, NewsItem, Slider } from 'components/shared';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RoutesData } from 'types';
import { dashboardActions, dashboardSelectors } from 'store/dashboardSlice';
import { calcsDecimals, createChartDates } from 'utils';

import styles from './Dashboard.module.scss';

const CalendarWidget = lazy(() => import('components/views/CalendarWidget'));
const DashboardChart = lazy(() => import('components/views/DashboardChart'));
const DashboardInfoCard = lazy(() => import('components/shared/DashboardInfoCard'));

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const historicalCommissions = useAppSelector(dashboardSelectors.selectHistoricalCommissions);
  const openCommissions = useAppSelector(dashboardSelectors.selectOpenCommissions);
  const reinvestedTokensSum = useAppSelector(dashboardSelectors.selectReinvestedTokensSum);
  const chartData = useAppSelector(dashboardSelectors.selectChartData);
  const startDate = `${chartData?.data?.chart?.[0]?.['year']}-${chartData?.data?.chart?.[0]?.['month']}`;
  const newsData = useAppSelector(dashboardSelectors.selectNewsListData);

  const [reinvestedTokensLoading, setReinvestedTokensLoading] = useState(true);

  const betweenMonths: any[] = createChartDates(startDate);

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        await dispatch(dashboardActions.getCommissionsSum()).unwrap();
        await dispatch(dashboardActions.getSalesChart()).unwrap();
        await dispatch(dashboardActions.getReinvestedTokensSum({})).unwrap();
        setReinvestedTokensLoading(false);
        dispatch(
          dashboardActions.getNewsList({
            skip: 0,
            take: 10,
            sort: 'publishAt',
            order: 'DESC',
          }),
        );
      } catch {
        /* empty */
      }
    };

    getDashboardData();
    return () => {
      dispatch(dashboardActions.reset());
    };
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <Suspense fallback={<Loader />}>
        <div className={styles.wrapper__header}>
          <BlockWrapper marginBottom='0'>
            <DashboardInfoCard
              coinValue='USDC'
              color='purple'
              likTo={RoutesData.Commissions}
              title='Open Commission'
              value={calcsDecimals.toCoin(Number(openCommissions), 'USDC').toFixed()}
            />
          </BlockWrapper>
          <BlockWrapper marginBottom='0'>
            <DashboardInfoCard
              coinValue='USDC'
              color='powder'
              likTo={RoutesData.Commissions}
              title='Historical Commission'
              value={calcsDecimals.toCoin(Number(historicalCommissions), 'USDC').toFixed()}
            />
          </BlockWrapper>
          <BlockWrapper marginBottom='0'>
            <DashboardInfoCard
              coinValue='XAB'
              color='mint'
              likTo={RoutesData.ReinvestedTokens}
              loading={reinvestedTokensLoading}
              title='Reinvested Tokens'
              value={
                Number(reinvestedTokensSum)
                  ? calcsDecimals.toCoin(Number(reinvestedTokensSum), 'XAB').toFixed()
                  : ''
              }
            />
          </BlockWrapper>
        </div>
        <div>
          <BlockWrapper marginBottom='0'>
            <DashboardChart labels={betweenMonths} title='Sales Chart' data={chartData.data} />
          </BlockWrapper>
        </div>
        <div className={styles.wrapper__footer}>
          <div className={styles.wrapper__footer__item}>
            <div className={styles.wrapper__footer__item__header}>
              <span className={styles.title}>Events Calendar</span>
              <Link to={`${RoutesData.Dashboard}/calendar`} className={styles.link}>
                View all
              </Link>
            </div>
            <CalendarWidget />
          </div>
          <div className={styles.wrapper__footer__item} style={{ marginLeft: '16px' }}>
            <div className={styles.wrapper__footer__item__header}>
              <span className={styles.title}>News Feed</span>
              <Link to={`${RoutesData.Dashboard}/news`} className={styles.link}>
                View all
              </Link>
            </div>
            <BlockWrapper marginBottom='0' padding='6px'>
              <div className={styles.wrapper__footer__item__news_block}>
                <Slider topPosition='49%'>
                  {newsData?.loading ? (
                    <Loader />
                  ) : (
                    newsData?.data?.map((news: any) => {
                      return (
                        <NewsItem
                          key={news.id}
                          date={moment(news?.publishAt).format('D MMM YYYY')}
                          details={news?.body}
                          id={news?.id}
                          img={news?.image}
                          title={news?.name}
                          type='small'
                        />
                      );
                    })
                  )}
                </Slider>
              </div>
            </BlockWrapper>
          </div>
        </div>
      </Suspense>
    </div>
  );
};

export default Dashboard;
