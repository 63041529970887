import { useCallback, useEffect, useMemo, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';

import { CopyIcon, Search } from 'assets/icons';
import { BlockWrapper } from 'components/layouts';
import {
  DatePicker,
  Input,
  NestedPartnersWidget,
  PartnerItem,
  Slider,
  Toast,
} from 'components/shared';
import { NestedPartnersTable } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { nestedPartnersActions, nestedPartnersSelectors } from 'store/nestedSlice';
import { calcsDecimals, calculatePeriod, checkIfValueIsEmpty } from 'utils';

import styles from './NestedPartners.module.scss';

const sliderSettings = {
  arrows: true,
  infinite: false,
  initialSlide: 0,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
};

const NestedPartners: React.FC = () => {
  const dispatch = useAppDispatch();
  const nestedPartnerByIdData = useAppSelector(nestedPartnersSelectors.selectNestedPartnerByIdData);
  const nestedPartnerByIdLoading = nestedPartnerByIdData.loading;
  const nestedPartnersData = useAppSelector(nestedPartnersSelectors.selectNestedPartnersData);
  const nestedPartnersStatisticData = useAppSelector(
    nestedPartnersSelectors.selectNestedPartnersStatisticData,
  );
  const statisticLoading = nestedPartnersStatisticData?.loading;

  const partners = useMemo(
    () =>
      nestedPartnersData?.data?.map((partner: any) => ({
        name: `${partner?.profile?.firstName}${checkIfValueIsEmpty(partner?.profile?.lastName)}`,
        id: partner?.id,
        info: { ...partner },
      })),
    [nestedPartnersData?.data],
  );

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [valueName, setValueName] = useState('');
  const [selectedPartner, setSelectedPartner] = useState(partners?.[0]);
  const [valueNameEmail, setValueNameEmail] = useState('');
  const [focus, setOnFocus] = useState(false);
  const [dateFrom, setDateFromValue] = useState<string | undefined>('');
  const [dateTo, setDateToValue] = useState<string | undefined>('');
  const [success, showSuccess] = useState(false);

  const filteredPartners = useMemo(() => {
    return valueName
      ? partners?.filter((partner: any) =>
          partner?.name.toLowerCase().includes(valueName.toLowerCase()),
        )
      : partners;
  }, [partners, valueName]);

  const handleSearch = (value: string) => {
    const inputValue = value.trim();
    setValueName(inputValue);
  };

  const handleDate = useCallback((value: any, dateFormat: string, endDate?: any) => {
    const period = calculatePeriod(value, dateFormat, endDate);
    setDateFromValue(period.dateFrom);
    setDateToValue(period.dateTo);
  }, []);

  const showSearch = useMemo(() => (valueName || focus ? true : false), [focus, valueName]);

  const sliderClassName = classNames(styles.slider, {
    [styles.slider__small]: showSearch,
    [styles.slider__big]: !showSearch,
  });

  useEffect(() => {
    dispatch(
      nestedPartnersActions.getNestedPartners({
        globalSearch: valueName,
      }),
    );
  }, [dispatch, valueName]);

  useEffect(() => {
    dispatch(
      nestedPartnersActions.getNestedPartnersStatistic({
        dateFrom,
        dateTo,
      }),
    );
  }, [dateFrom, dateTo, dispatch]);

  useEffect(() => {
    if (selectedPartner?.id) {
      dispatch(
        nestedPartnersActions.getNestedPartnerById({
          id: selectedPartner?.id,
          skip,
          take,
          sort: 'createdAt',
          order: 'DESC',
          globalSearch: valueNameEmail,
          dateFrom,
          dateTo,
        }),
      );
    }
  }, [dateFrom, dateTo, dispatch, selectedPartner, skip, take, valueNameEmail]);

  useEffect(() => {
    setSelectedPartner(partners?.[0]);
  }, [partners]);

  useEffect(() => {
    if (success) {
      setTimeout(() => showSuccess(false), 3000);
    }
  }, [success]);

  const purchasesSumXab = statisticLoading
    ? '0'
    : calcsDecimals.toCoin(nestedPartnersStatisticData?.data?.xabPurchasesSumXab, 'XAB')?.toFixed();
  const purchasesSumUsd = statisticLoading
    ? '0'
    : calcsDecimals
        .toCoin(nestedPartnersStatisticData?.data?.xabPurchasesSumUsd, 'USD')
        ?.toFixed(2);

  const reinvestedTokensSum = statisticLoading
    ? '0'
    : calcsDecimals
        .toCoin(nestedPartnersStatisticData?.data?.reinvestedTokensSum, 'XAB')
        ?.toFixed();

  const toastSuccessClassNames = classNames(styles.toaster, {
    [styles.toaster__success]: success,
  });

  return (
    <div style={{ minHeight: '100vh', width: '90%' }}>
      <BlockWrapper marginBottom='20px'>
        <div className={styles.wrapper}>
          <div className={styles.wrapper__header_block}>
            <div className={styles.title_block}>
              <h4 className={styles.title_block__title}>Nested Partners</h4>
            </div>
            <div className={styles.date_range_block}>
              <DatePicker onChangeDate={handleDate} />
            </div>
          </div>
          <div className={styles.wrapper__widget_block}>
            <NestedPartnersWidget
              currency='USD'
              title='Total Purchased'
              subTitle='from Nested Partners'
              subCurrency='XAB'
              subValue={isNaN(Number(purchasesSumXab)) ? '0' : purchasesSumXab}
              value={isNaN(Number(purchasesSumUsd)) ? '0' : purchasesSumUsd}
            />
            <NestedPartnersWidget
              currency='XAB'
              title='Total Reinvested'
              subTitle='from Nested Partners'
              value={isNaN(Number(reinvestedTokensSum)) ? '0' : reinvestedTokensSum}
            />
          </div>
          <div className={styles.wrapper__choose_partner_block}>
            <span className={styles.wrapper__choose_partner_block__title}>Choose a partner</span>
            <div className={styles.wrapper__choose_partner_block__slider_block}>
              <div className={showSearch ? styles.search_box_big : styles.search_box_small}>
                <div style={{ width: '100%' }}>
                  <Input
                    adornment={
                      <Tooltip followCursor={true} placement='top' title='Search'>
                        <Search />
                      </Tooltip>
                    }
                    className={styles.wrapper__choose_partner_block__slider_block__search}
                    name='search_name'
                    onBlur={() => setOnFocus(false)}
                    onChange={(e: any) => handleSearch(e.target.value)}
                    onClear={() => handleSearch('')}
                    onFocus={() => setOnFocus(true)}
                    placeHolder='Search by name'
                    value={valueName}
                    view='startAdornment'
                    withClear={showSearch && Boolean(valueName)}
                  />
                </div>
              </div>
              {!filteredPartners?.length ? (
                <span className={styles.no_result}>No results</span>
              ) : (
                <div className={sliderClassName}>
                  <Slider settings={sliderSettings} topPosition='-10%'>
                    {filteredPartners?.map((element: any) => {
                      const isSelected = selectedPartner?.name === element?.name;
                      return (
                        <div
                          key={element.id}
                          onClick={() => {
                            setSelectedPartner(element);
                            setValueNameEmail('');
                          }}
                          role='button'
                        >
                          <PartnerItem isSelected={isSelected} name={element?.name} />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              )}
            </div>
          </div>
          <div className={styles.wrapper__partner_block}>
            <div className={styles.wrapper__partner_block__item} style={{ paddingLeft: '0' }}>
              <span className={styles.full_name}>{selectedPartner?.name}</span>
              <span>
                Purchased{' '}
                {nestedPartnerByIdLoading ? (
                  ''
                ) : (
                  <>
                    <b>
                      {nestedPartnerByIdData?.data?.xabPurchasesSumXab
                        ? calcsDecimals
                            .toCoin(nestedPartnerByIdData?.data?.xabPurchasesSumXab, 'XAB')
                            .toFixed()
                        : '0'}
                    </b>{' '}
                    XAB (
                    <b>
                      {nestedPartnerByIdData?.data?.xabPurchasesSumUsd
                        ? calcsDecimals
                            .toCoin(nestedPartnerByIdData?.data?.xabPurchasesSumUsd, 'USD')
                            .toFixed(2)
                        : '0'}
                    </b>{' '}
                    USD)
                  </>
                )}
              </span>
              <span>
                Reinvested{' '}
                {nestedPartnerByIdLoading ? (
                  ''
                ) : (
                  <>
                    <b>
                      {nestedPartnerByIdData?.data?.reinvestedTokensSum
                        ? calcsDecimals
                            .toCoin(nestedPartnerByIdData?.data?.reinvestedTokensSum, 'XAB')
                            .toFixed()
                        : '0'}
                    </b>{' '}
                    XAB
                  </>
                )}
              </span>
            </div>
            <div className={styles.wrapper__partner_block__item}>
              <span>
                <b>Email</b> {selectedPartner?.info?.email}
              </span>
              <span>
                <b>Phone Number</b>{' '}
                {checkIfValueIsEmpty(nestedPartnerByIdData?.data?.user?.profile?.phone)}
              </span>
              <span className={styles.referralCode}>
                <b>Referral Code</b>
                <span style={{ margin: '0 4px' }}>
                  {checkIfValueIsEmpty(
                    nestedPartnerByIdData?.data?.user?.affiliateProfile?.referralCode,
                  )}
                </span>
                <div
                  className={styles.copy_btn}
                  onClick={async () => {
                    if ('clipboard' in navigator) {
                      await navigator.clipboard.writeText(
                        nestedPartnerByIdData?.data?.user?.affiliateProfile?.referralCode,
                      );
                      showSuccess(true);
                    }
                  }}
                >
                  Copy
                  <CopyIcon />
                </div>
              </span>
            </div>
            <div className={styles.wrapper__partner_block__search}>
              <Input
                adornment={
                  <Tooltip followCursor={true} placement='top' title='Search'>
                    <Search />
                  </Tooltip>
                }
                name='search_name_email'
                onChange={(e: any) => setValueNameEmail(e.target.value)}
                placeHolder='Search by name or email'
                value={valueNameEmail}
                view='startAdornment'
              />
            </div>
          </div>
          <div className={styles.wrapper__table_block}>
            <NestedPartnersTable setSkip={setSkip} setTake={setTake} skip={skip} take={take} />
          </div>
        </div>
      </BlockWrapper>
      <Toast
        showToast={success}
        message={`Referral Code copied`}
        toastClassNames={toastSuccessClassNames}
      />
    </div>
  );
};

export default NestedPartners;
