import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

import { calcsDecimals } from 'utils';

import styles from './ReinvestedTokensTable.module.scss';

const ReinvestedTokensRow = ({ row }: any) => (
  <>
    <TableRow className={styles.container__body__row}>
      <TableCell align='left' className={styles.ceil}>
        {row?.email}
      </TableCell>
      <TableCell align='center' className={styles.ceil}>
        {calcsDecimals.toCoin(Number(row?.amount), 'XAB').toFixed()}
      </TableCell>
      <TableCell align='right' className={styles.ceil}>
        {moment(row?.createdAt).utc().format('MMM DD, YYYY hh:mm A')}
      </TableCell>
    </TableRow>
  </>
);

export default ReinvestedTokensRow;
