import { useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';

import { Search } from 'assets/icons';
import { BlockWrapper } from 'components/layouts';
import { CustomersTable } from 'components/views';
import { Input } from 'components/shared';
import { useAppDispatch } from 'hooks';
import { customersActions } from 'store/customersSlice';

import styles from './Customers.module.scss';

const Customers: React.FC = () => {
  const dispatch = useAppDispatch();
  const customersRef = useRef<ReturnType<typeof setInterval> | string>('');

  const [serachValue, setSearchValue] = useState('');
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  const getCustomers = useCallback(
    (search?: string) => {
      dispatch(
        customersActions.getCustomers({
          skip,
          take,
          sort: 'createdAt',
          order: 'DESC',
          globalSearch: search,
        }),
      );
    },
    [dispatch, skip, take],
  );

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const handleSearch = (value: string) => {
    setSkip(0);
    const inputValue = value.trim();
    setSearchValue(inputValue);

    clearTimeout(customersRef.current);
    customersRef.current = setTimeout(() => getCustomers(inputValue), 1_000);
  };

  return (
    <div style={{ width: '99%' }}>
      <BlockWrapper title='Customers'>
        <div className={styles.wrapper}>
          <div className={styles.wrapper__header_block}>
            <Input
              adornment={
                <Tooltip followCursor={true} placement='left-start' title='Search'>
                  <Search />
                </Tooltip>
              }
              name='search_customer'
              onChange={(e: any) => handleSearch(e.target.value)}
              placeHolder='Search by name or email'
              value={serachValue}
              view='startAdornment'
            />
          </div>

          <div className={styles.wrapper__table_block}>
            <CustomersTable setSkip={setSkip} setTake={setTake} skip={skip} take={take} />
          </div>
        </div>
      </BlockWrapper>
    </div>
  );
};

export default Customers;
