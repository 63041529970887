import { RoutesData } from 'types';
import { Dashboard, Customers, Commissions, Profile, Payout, NestedIcon } from 'assets/icons';

type SidebarNavigationItem = {
  id: number;
  text: string;
  linkTo: RoutesData;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  admit: string | string[];
};

const sidebarNavigation: SidebarNavigationItem[] = [
  {
    id: 1,
    text: 'Dashboard',
    Icon: Dashboard,
    linkTo: RoutesData.Dashboard,
    admit: 'ALL',
  },
  {
    id: 2,
    Icon: Customers,
    text: 'Customers',
    linkTo: RoutesData.Customers,
    admit: 'ALL',
  },
  {
    id: 3,
    Icon: Commissions,
    text: 'Commissions',
    linkTo: RoutesData.Commissions,
    admit: 'ALL',
  },
  {
    id: 4,
    Icon: NestedIcon,
    text: 'Nested Partners',
    linkTo: RoutesData.NestedPartners,
    admit: 'ALL',
  },
  {
    id: 5,
    Icon: Payout,
    text: 'Payouts',
    linkTo: RoutesData.Payouts,
    admit: 'ALL',
  },
  {
    id: 6,
    Icon: Profile,
    text: 'Profile',
    linkTo: RoutesData.Profile,
    admit: 'ALL',
  },
];

export default sidebarNavigation;
