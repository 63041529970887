type CustomerTransactions = { id: string; name: string };

export enum TabType {
  xab_transactions = 'xab_transactions',
  bonus_transactions = 'bonus_transactions',
}

const customerTransactionsTabs: CustomerTransactions[] = [
  {
    id: TabType.xab_transactions,
    name: 'XAB Transactions',
  },
  {
    id: TabType.bonus_transactions,
    name: 'Bonus Transactions',
  },
];

export default customerTransactionsTabs;
