import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { CustomersSliceState } from './types';

const selectCustomers = (state: RootState): CustomersSliceState => state.customers;

export const selectCustomersData = createSelector(
  selectCustomers,
  (customers) => customers.customersData,
);

export const selectCustomersDataError = createSelector(
  selectCustomers,
  (customers) => customers.customersDataError,
);

export const selectCustomersDataLoading = createSelector(
  selectCustomers,
  (customers) => customers.customersDataLoading,
);

export const selectCurrentCustomer = createSelector(
  selectCustomers,
  (customers) => customers.currentCustomer,
);

export const selectCustomerXABTransactions = createSelector(
  selectCustomers,
  (customers) => customers.customersXABTransactionsData,
);

export const selectCustomerBonusTransactions = createSelector(
  selectCustomers,
  (customers) => customers.customersBonusTransactionsData,
);
