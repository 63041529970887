import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './DashboardInfoCard.module.scss';
import { IDashboardInfoCard } from './types';

function DashboardInfoCard({
  coinValue,
  color = 'mint',
  likTo,
  loading,
  title,
  value,
}: IDashboardInfoCard) {
  const sideColorElementClassName = classNames(styles.wrapper__color, {
    [styles[color]]: color,
  });

  const cardValue = value ? value : 0;

  return (
    <div className={styles.wrapper}>
      <div className={sideColorElementClassName}></div>
      <div className={styles.wrapper__content}>
        <div className={styles.title}>
          <h3>{title}</h3>
          <Link to={likTo}>{color === 'purple' ? 'More' : 'View'}</Link>
        </div>
        <div className={styles.wrapper__value_block}>
          <h4>{!loading && cardValue}</h4>
          <span>{coinValue}</span>
        </div>
      </div>
    </div>
  );
}

export default DashboardInfoCard;
