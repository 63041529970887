import { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import classNames from 'classnames';

import { BlockWrapper } from 'components/layouts';
import { useAppDispatch, useAppSelector } from 'hooks';
import { dashboardActions, dashboardSelectors } from 'store/dashboardSlice';
import { RoutesData } from 'types';

import styles from './Calendar.module.scss';

const currentDate = new Date();

const CalendarScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const eventsData = useAppSelector(dashboardSelectors.selectEventsList);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    dispatch(
      dashboardActions.getEventsList({
        skip: 0,
        take: 10,
        sort: 'createdAt',
        order: 'DESC',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (eventsData.data.length) {
      const events = eventsData.data.map((event: any) => {
        return {
          ...event,
          date: moment.utc(event.startAt).format('YYYY-MM-DD'),
          publicId: event.id,
          title: event.name,
        };
      });

      setEvents(events);
    }
  }, [eventsData.data]);

  return (
    <div className={styles.wrapper}>
      <BlockWrapper
        backTo='Back to dashboard'
        backToPath={RoutesData.Dashboard}
        marginBottom='0'
        padding='12px'
        title='Events Calendar'
      >
        <div className={styles.wrapper__calendar}>
          <FullCalendar
            events={events}
            eventContent={renderEventContent}
            eventMaxStack={2}
            initialView='dayGridMonth'
            plugins={[dayGridPlugin, interactionPlugin]}
            weekends={true}
            dayMaxEventRows
            headerToolbar={{
              start: '',
              center: 'title',
              end: 'prev next',
            }}
          />
        </div>
      </BlockWrapper>
    </div>
  );
};

export default memo(CalendarScreen);

function renderEventContent(eventInfo: any) {
  const isPast = moment(eventInfo.event._def.extendedProps.startAt).isBefore(currentDate);
  const eventClassName = classNames(styles.event__container, {
    [styles.event__container__past_event]: isPast,
  });

  return (
    <Link
      className={eventClassName}
      style={{ borderColor: eventInfo.borderColor }}
      to={`${RoutesData.Dashboard}/calendar/${eventInfo.event._def.extendedProps.publicId}`}
    >
      <span className={styles.event__container__title}>
        {eventInfo.event._def.extendedProps.name}
      </span>
      <span className={styles.event__container__time}>
        {moment.utc(eventInfo.event._def.extendedProps.startAt).format('HH:mm')}
      </span>
    </Link>
  );
}
