import { authApi } from '../../api';
import BrowserStorageService from '../browserStorage';
import { BrowserStorageKeys } from '../browserStorage/types';

class Auth {
  async verifyUrlParams() {
    const urlParams = new URLSearchParams(window.location.search);

    const entries = urlParams.entries();
    const params = this.#paramsToObject(entries);

    if (params?.code) {
      try {
        const response = await authApi.activateEmailRequest({ code: params.code });
        if (response.status === 201) {
          BrowserStorageService.set(BrowserStorageKeys.UserId, response.data.id, { session: true });
        }
        return response?.data;
      } catch (error) {
        const err = error;
        return err;
      }
    }
  }

  #paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
}

export default new Auth();
