export const STATUS_ERROR = 'This activation code is not found';
export const NOT_FOUND = 'This activation code is not found';
export const CONFLICT = 'Email has been already activated';
export const GONE = 'Link has been expired';
export const INTERNAL_SERVER_ERROR = 'INTERNAL SERVER ERROR';

export const ERRORS = {
  403: GONE,
  404: NOT_FOUND,
  409: CONFLICT,
  410: GONE,
  500: INTERNAL_SERVER_ERROR,
};

export const ERROR_CODES = {
  403: 403,
  404: 404,
  409: 409,
  410: 410,
  429: 429,
  500: 500,
  503: 503,
};

export const ERRORS_MESSAGE = {
  signInError: '* Wrong password or email',
  statusError: (status: string) =>
    `* Your account is ${status.toLowerCase()}. Please contact with your Admin`,
  tooManyRequests: '* Too many requests',
};
