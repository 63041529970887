import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

import { calcsDecimals } from 'utils';

import { BonusTransactionTypes } from '../../../../constants';

import styles from './CustomerBonusTable.module.scss';

const CustomerBonusRow = ({ row }: any) => {
  const category:
    | 'XAB_LOCK'
    | 'XAB_UNLOCK'
    | 'BONUS_CONVERT'
    | 'BONUS_PAYOUT_SUCCESS'
    | 'BONUS_PAYOUT_REJECT'
    | 'BONUS_REINVEST' = row?.category;

  return (
    <>
      <TableRow className={styles.container__body__row}>
        <TableCell align='left' className={styles.ceil}>
          {BonusTransactionTypes[category]}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {row?.type}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {category === 'BONUS_PAYOUT_SUCCESS' ||
          category === 'BONUS_PAYOUT_REJECT' ||
          category === 'BONUS_CONVERT' ||
          category === 'BONUS_REINVEST'
            ? calcsDecimals.toCoin(Number(row?.amount), 'CURRENCY').toFixed()
            : calcsDecimals.toCoin(Number(row?.amount), 'XAB').toFixed()}
        </TableCell>
        <TableCell align='right' className={styles.ceil}>
          {moment(row?.createdAt).utc().format('MMM DD, YYYY hh:mm A')}
        </TableCell>
      </TableRow>
    </>
  );
};

export default CustomerBonusRow;
