import { useNavigate } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

import { CustomerAction, NoReason } from 'assets/icons';
import { useAppDispatch } from 'hooks';
import { customersActions } from 'store/customersSlice';
import { RoutesData } from 'types';
import { calcsDecimals, checkIfValueIsEmpty, createUserFriendlyStatus } from 'utils';

import styles from './CustomersTable.module.scss';

const CustomerRow = ({ row }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleRowClick = () => {
    dispatch(customersActions.setCurrentCustomer(row));
    navigate(`${RoutesData.Customers}/${row?.id}`);
  };

  const status = createUserFriendlyStatus(row?.status);

  return (
    <>
      <TableRow className={styles.container__body__row} onClick={handleRowClick}>
        <TableCell align='left' className={styles.ceil}>
          {checkIfValueIsEmpty(row?.firstName)} {checkIfValueIsEmpty(row?.lastName)}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {checkIfValueIsEmpty(row?.email)}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {checkIfValueIsEmpty(row?.phone)}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {moment(row?.createdAt).utc().format('MMM DD, YYYY hh:mm A')}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {row?.isVerified ? 'YES' : <span style={{ color: '#D06643' }}>NO</span>}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {calcsDecimals.toCoin(row?.xabBalance, 'XAB').toFixed()}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {calcsDecimals.toCoin(row?.lockedBalance, 'XAB').toFixed()}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {Number(row?.bonusBalance) / 10000}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {calcsDecimals.toCoin(row?.totalReinvested, 'XAB').toFixed()}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          <span className={styles.ceil__status} style={status?.style}>
            {status?.name}
          </span>
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {row?.statusNotes ? row?.statusNotes : <NoReason />}
        </TableCell>
        <TableCell align='right' className={styles.ceil}>
          <CustomerAction />
        </TableCell>
      </TableRow>
    </>
  );
};

export default CustomerRow;
