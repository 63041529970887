import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { NestedPartnersSliceState } from './types';

const selectNestedPartners = (state: RootState): NestedPartnersSliceState => state.nestedPartners;

export const selectNestedPartnersData = createSelector(
  selectNestedPartners,
  (nested) => nested.nestedPartnersData,
);

export const selectNestedPartnerByIdData = createSelector(
  selectNestedPartners,
  (nested) => nested.nestedPartnerByIdData,
);

export const selectNestedPartnersStatisticData = createSelector(
  selectNestedPartners,
  (nested) => nested.nestedPartnerStatistic,
);
