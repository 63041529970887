import { Logo } from 'assets/icons';
import { BlockWrapper, Loader } from 'components';

import styles from '../SetNewPassword.module.scss';

const Loading: React.FC = () => {
  return (
    <BlockWrapper marginBottom='0'>
      <div className={styles.wrapper}>
        <Logo />
        <Loader />
      </div>
    </BlockWrapper>
  );
};

export default Loading;
