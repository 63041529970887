import TableBody from '@mui/material/TableBody';

import { EmptyData, Loader, Pagination, ScrollWrapper, Table } from 'components/shared';
import { reinvestedTokensTable } from 'constants/index';
import { useAppSelector } from 'hooks';
import { dashboardSelectors } from 'store/dashboardSlice';
import { ITableTypes } from 'types';

import ReinvestedTokensRow from './ReinvestedTokensRow';
import styles from './ReinvestedTokensTable.module.scss';

const ReinvestedTokensTable = ({ setSkip, setTake, skip, take }: ITableTypes) => {
  const loading = useAppSelector(dashboardSelectors.selectReinvestedTokenLoading);
  const data = useAppSelector(dashboardSelectors.selectReinvestedTokens);
  const totalCount = data.totalCount || 1;

  const handlePage = (_event: unknown, newPage: number) => {
    setSkip(newPage * take);
  };
  const handleRows = (event: any) => {
    setTake(event.target.value);
    setSkip(0);
  };

  return (
    <>
      <div>
        <h3 className={styles.table_title}>Reinvestment transactions</h3>
        <ScrollWrapper>
          {loading ? (
            <Loader />
          ) : (
            <Table headerArray={reinvestedTokensTable}>
              <TableBody>
                {data?.list?.map((row: any) => (
                  <ReinvestedTokensRow row={row} key={row.email} />
                ))}
              </TableBody>
            </Table>
          )}
          {!data.totalCount && !loading && <EmptyData />}
        </ScrollWrapper>
        <Pagination
          handleChangePage={handlePage}
          handleChangeRowsPerPage={handleRows}
          currentPage={skip / take || 0}
          rowsPerPage={take}
          totalCount={totalCount}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      </div>
    </>
  );
};
export default ReinvestedTokensTable;
