import { useEffect } from 'react';
import TableBody from '@mui/material/TableBody';

import { EmptyData, Loader, Pagination, Table } from 'components/shared';
import { customerXABTable } from 'constants/index';
import { useAppDispatch, useAppSelector } from 'hooks';
import { customersActions, customersSelectors } from 'store/customersSlice';

import CustomerXABRow from './CustomerXABRow';
import styles from './CustomerXABTable.module.scss';

const CustomerXABTable = ({ dateFrom, dateTo, id, select, skip, take, setSkip, setTake }: any) => {
  const dispatch = useAppDispatch();

  const customersXABData = useAppSelector(customersSelectors.selectCustomerXABTransactions);
  const totalCount = customersXABData.totalCount || 1;
  const list = customersXABData.list;
  const loading = customersXABData.loading;

  const handlePage = (_event: unknown, newPage: number) => {
    setSkip(newPage * take);
  };
  const handleRows = (event: any) => {
    setTake(event.target.value);
    setSkip(0);
  };

  useEffect(() => {
    if (id) {
      dispatch(
        customersActions.getCustomersXAB({
          userId: id,
          skip,
          take,
          sort: 'createdAt',
          order: 'DESC',
          dateFrom,
          dateTo,
          filter: `{ "category": "${select}" }`,
        }),
      );
    }
  }, [dateFrom, dateTo, dispatch, id, select, skip, take]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.wrapper}>
          <Table headerArray={customerXABTable}>
            <TableBody>
              {list?.map((row: any) => (
                <CustomerXABRow row={row} key={row.id} />
              ))}
            </TableBody>
          </Table>
          {!customersXABData.totalCount && <EmptyData />}
        </div>
      )}

      <Pagination
        handleChangePage={handlePage}
        handleChangeRowsPerPage={handleRows}
        currentPage={skip / take || 0}
        rowsPerPage={take}
        totalCount={totalCount}
      />
    </>
  );
};
export default CustomerXABTable;
