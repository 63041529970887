import { useEffect, useState } from 'react';
import Switch from 'react-switch';

function CustomSwitch({
  activeBoxShadow = '#AFAFAF',
  borderRadius,
  callback,
  checked,
  checkedIcon = null,
  className,
  disabled,
  handleDiameter = 18,
  height = 26,
  offColor = '#AFAFAF',
  onColor = '#D06643',
  uncheckedIcon = null,
  width,
}: any) {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  const handleValue = (e: any) => {
    callback && callback(e);
    setValue(e);
  };

  return (
    <Switch
      activeBoxShadow={activeBoxShadow}
      borderRadius={borderRadius}
      checked={value}
      checkedIcon={checkedIcon}
      className={className}
      disabled={disabled}
      handleDiameter={handleDiameter}
      height={height}
      offColor={offColor}
      onChange={handleValue}
      onColor={onColor}
      uncheckedIcon={uncheckedIcon}
      width={width}
    />
  );
}
export default CustomSwitch;
