import { Logo } from 'assets/icons';
import { BlockWrapper } from 'components';

import styles from '../ConfirmRegistration.module.scss';

interface ILinkError {
  linkErrorMessage: string;
}

const LinkError = ({ linkErrorMessage }: ILinkError) => {
  return (
    <BlockWrapper marginBottom='0'>
      <div className={styles.wrapper}>
        <Logo />
        <div className={styles.wrapper__title_error}>{linkErrorMessage}</div>
        <div className={styles.wrapper__subtitle}>
          To get a valid registration confirmation link, contact the administrator.
        </div>
        <div style={{ marginTop: '70px' }}></div>
      </div>
    </BlockWrapper>
  );
};

export default LinkError;
