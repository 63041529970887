import classNames from 'classnames';

import { Back } from 'assets/icons';

import { IArrow } from './types';
import styles from './Slider.module.scss';

const CustomArrow = ({ className, onClick, top = '18%', type }: IArrow) => {
  const arrowNextClassName = classNames(styles['slider_arrow_next'], {
    [styles['slider_arrow_next__disabled']]: className?.includes('next slick-disabled'),
  });

  const arrowPrevClassName = classNames(styles['slider_arrow_prev'], {
    [styles['slider_arrow_next__disabled']]: className?.includes('prev slick-disabled'),
  });

  const renderArrow = {
    next: (
      <div className={arrowNextClassName} onClick={onClick} style={{ top: top }}>
        <Back />
      </div>
    ),
    prev: (
      <div className={arrowPrevClassName} onClick={onClick} style={{ top: top }}>
        <Back />
      </div>
    ),
  };

  return <>{renderArrow[type]}</>;
};

export default CustomArrow;
