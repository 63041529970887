import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

import { calcsDecimals } from 'utils';

import { XabDepositTransactionTypes } from '../../../../constants';

import styles from './CustomerXABTable.module.scss';

const CustomerXABRow = ({ row }: any) => {
  const category:
    | 'ADMIN_DEPOSIT'
    | 'REFILL_BY_CAPITEUS'
    | 'XAB_PURCHASE_CRYPTO'
    | 'XAB_PURCHASE_OTC' = row?.category;

  return (
    <>
      <TableRow className={styles.container__body__row}>
        <TableCell align='left' className={styles.ceil}>
          {XabDepositTransactionTypes[category]}
        </TableCell>
        <TableCell align='center' className={styles.ceil}>
          {calcsDecimals.toCoin(Number(row?.amount), 'XAB').toFixed()}
        </TableCell>
        <TableCell align='right' className={styles.ceil}>
          {moment(row?.createdAt).utc().format('MMM DD, YYYY hh:mm A')}
        </TableCell>
      </TableRow>
    </>
  );
};

export default CustomerXABRow;
