const TFA_off_info = [
  '1. Open the Google authenticator app.',
  '2. Check 6-digit code and enter into the Secret code field below.',
  '3. Click Confirm.',
  '4. If 6-digit code is valid, 2FA will be disabled for your account.',
];

const TFA_on_info = [
  "1. If you don't have Google Authenticator app, please install it on your smartphone.",
  '2. Open Google authenticator app on your device and tap "+" icon to add new profile.',
  '3. You can scan QR code from this page with Google Authenticator. Or you can copy setup key from this page and insert it to the Google authenticator.',
  '4. Now you see the 6-digit code next to the newly created profile in Google Authenticator.',
  '5. Enter this code to the Secret code field and Click Confirm.',
  '6. If entered code is valid, you will see Success message. 2FA is enabled for your Teroxx Exclusive account.',
];

const TFA_info = {
  TFA_off_info,
  TFA_on_info,
};

export default TFA_info;
