import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { BrowserStorageKeys, BrowserStorageService, MaintenanceValues } from 'services';
import { RoutesData } from 'types';

const PublicRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>('');
  const isMaintenance =
    BrowserStorageService.get(BrowserStorageKeys.IsMaintenance) === MaintenanceValues.Active;

  useEffect(() => {
    setToken(BrowserStorageService.get(BrowserStorageKeys.AccessToken));
  }, []);

  if (isMaintenance) {
    return navigate(0);
  }

  if (token && !isMaintenance) {
    return <Navigate to={RoutesData.Dashboard} replace />;
  }

  return children;
};

export default PublicRoute;
