import { Container } from 'components/shared';
import { Sidebar } from 'components/views';

import styles from './Seo.module.scss';

const Seo = ({ children, withSidebar = true }: any) => {
  return (
    <main className='wrapper'>
      {withSidebar ? (
        <>
          <Sidebar />
          <Container>{children}</Container>
        </>
      ) : (
        <Container className={styles.login__wrapper}>{children}</Container>
      )}
    </main>
  );
};

export default Seo;
