import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { CommissionsSliceState } from './types';

const selectCustomers = (state: RootState): CommissionsSliceState => state.commissions;

export const selectCommissionsData = createSelector(
  selectCustomers,
  (commissions) => commissions.commissionsData,
);
