import { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Seo, ProtectedRoute, PublicRoute } from 'components';
import { RoutesData, RoutesProps } from 'types';
import { BrowserStorageKeys, BrowserStorageService, MaintenanceValues } from 'services';

import { Maintenance } from '../containers';

import routes from './routes';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RouterProvider = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  useEffect(() => {
    const maintenanceKey = BrowserStorageService.get(BrowserStorageKeys.IsMaintenance);
    setIsMaintenance(maintenanceKey === MaintenanceValues.Active);
  }, []);

  const renderRoutes = useMemo(
    () =>
      routes.map(({ path, component, isProtected, withSidebar }: RoutesProps) => {
        const RouteWrapper = isProtected ? ProtectedRoute : PublicRoute;

        if (isMaintenance) {
          return (
            <Route
              element={<Maintenance />}
              path={RoutesData.Default}
              key={RoutesData.Maintenance}
            />
          );
        }

        return (
          <Route
            element={
              <RouteWrapper>
                <Seo withSidebar={withSidebar}>{component}</Seo>
              </RouteWrapper>
            }
            key={path}
            path={path}
          />
        );
      }),
    [isMaintenance],
  );

  return (
    <BrowserRouter>
      <Routes>
        {renderRoutes}
        <Route path={RoutesData.Default} element={<Navigate to={RoutesData.Dashboard} replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterProvider;
