import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { ProfileSliceState } from './types';

const selectProfile = (state: RootState): ProfileSliceState => state.profile;

export const selectProfileData = createSelector(selectProfile, (profile) => profile.profile);
export const selectProfileError = createSelector(selectProfile, (profile) => profile.profileError);
export const selectProfileLoading = createSelector(
  selectProfile,
  (profile) => profile.profileloading,
);
