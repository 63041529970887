import { HeadCell } from 'types';

const customersTable: HeadCell[] = [
  {
    id: 'name',
    value: 'name',
    label: 'Full Name',
  },
  {
    id: 'email',
    value: 'email',
    label: 'Email',
  },
  {
    id: 'phone',
    value: 'phone',
    label: 'Phone number',
  },
  {
    id: 'registered',
    value: 'registered',
    label: 'Registered',
  },
  {
    id: 'verified',
    value: 'verified',
    label: 'Verified',
  },
  {
    id: 'balance',
    value: 'balance',
    label: ['Abloxx Token ', 'Balance, XAB'],
    isArray: true,
  },
  {
    id: 'locked_balance',
    value: 'locked_balance',
    label: ['Abloxx Locked ', 'Token Balance, XAB'],
    isArray: true,
  },
  {
    id: 'bonus_balance',
    value: 'bonus_balance',
    label: ['Bonus Wallet ', 'Balance, USD'],
    isArray: true,
  },
  {
    id: 'total_token',
    value: 'total_token',
    label: ['Total Reinvest ', 'Token, XAB'],
    isArray: true,
  },
  {
    id: 'status',
    value: 'status',
    label: 'Status',
  },
  {
    id: 'statusNotes',
    value: 'statusNotes',
    label: 'Reason',
  },
  {
    id: 'action',
    value: 'action',
    label: 'View',
  },
];

export default customersTable;
