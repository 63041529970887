import { useState } from 'react';
import { UseFormProps, UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';

import { Logo } from 'assets/icons';
import { BlockWrapper, Button, Copyright, Input } from 'components';
import { useAppDispatch } from 'hooks';
import { authActions } from 'store/authSlice';
import { RoutesData } from 'types';
import { validationForgotPassSchema } from 'utils';

import styles from '../Login.module.scss';
import { IForgotPassForm } from '../types';

const defaultValues: IForgotPassForm = {
  email: '',
};

const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch();

  const [success, showSuccess] = useState(false);

  const form: UseFormReturn<IForgotPassForm, UseFormProps> = useForm<IForgotPassForm>({
    defaultValues,
    resolver: yupResolver(validationForgotPassSchema),
  });

  const emailField = form.register('email');

  const handleSendLink = async ({ email }: IForgotPassForm) => {
    const emailObj = {
      email,
    };
    try {
      const response = await dispatch(authActions.forgotPassword(emailObj)).unwrap();
      if (response.status === 201) {
        showSuccess(true);
      }
    } catch (error: any) {
      /** temporary */
      window.location.reload();
    }
  };

  return (
    <div className={styles.container}>
      <form autoComplete='off' onSubmit={form.handleSubmit(handleSendLink)}>
        <BlockWrapper marginBottom='0'>
          {success ? (
            <div className={styles.wrapper}>
              <Logo />
              <div className={styles.wrapper__title} style={{ marginTop: '88px' }}>
                Recover your password
              </div>
              <div className={styles.wrapper__subtitle}>
                Recovery link sent. Please, check your email.
              </div>
              <div style={{ marginTop: '88px' }}></div>
              <Link
                className={styles.go_to}
                onClick={() => showSuccess(true)}
                role='button'
                style={{ marginTop: '0px' }}
                to={RoutesData.Login}
              >
                Back to login page
              </Link>
            </div>
          ) : (
            <div className={styles.wrapper}>
              <div className={styles.wrapper__title}>Recover your password</div>
              <div className={styles.wrapper__form_box}>
                <Input
                  {...emailField}
                  error={form.formState.errors.email?.message}
                  id='forgot_email'
                  label='Email'
                  placeHolder='Enter email'
                />
              </div>
              <Button title='Send recover link' type='submit' />
              <Link to={RoutesData.Login} className={styles.go_to}>
                Back to login page
              </Link>
            </div>
          )}
        </BlockWrapper>
      </form>
      <Copyright />
    </div>
  );
};

export default ForgotPassword;
