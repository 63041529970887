import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { authApi, client } from 'api';
import { Slice } from 'types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

export const signIn = createAsyncThunk(
  `${Slice.Auth}/signIn`,
  async (credentials: { email: string; password: string; rememberMe?: boolean }, thunkAPI) => {
    const { rememberMe, ...restCredentials } = credentials;

    try {
      const response = await authApi.signInRequest(restCredentials);
      if (!response.data.twoFactorAuthEnabled) {
        BrowserStorageService.set(BrowserStorageKeys.AccessToken, response.data.token, {
          session: !rememberMe,
        });
        BrowserStorageService.set(BrowserStorageKeys.Role, response.data.role);
      } else {
        BrowserStorageService.set(BrowserStorageKeys.AccessToken, response.data.token, {
          session: true,
        });
      }

      BrowserStorageService.set(
        BrowserStorageKeys.IsTwoFactorAuth,
        response.data.twoFactorAuthEnabled,
        { session: true },
      );

      return {
        accessToken: response.data.token,
        data: response.data,
        role: response.data.role,
        twoFactorAuthEnabled: response.data.twoFactorAuthEnabled,
      };
    } catch (error) {
      const err = error as AxiosError<any>;
      return thunkAPI.rejectWithValue({
        error: err,
      });
    }
  },
);

export const verifyTwoFA = createAsyncThunk(
  `${Slice.Auth}/verifyTwoFA`,
  async ({ otp }: any, thunkAPI) => {
    try {
      const response = await authApi.verifyTwoFARequest(otp);
      BrowserStorageService.set(BrowserStorageKeys.AccessToken, response.data.token);

      return {
        accessToken: response.data.token,
        data: response.data,
      };
    } catch (error) {
      const err = error as AxiosError<any>;
      return thunkAPI.rejectWithValue({
        error: err.response?.data?.message,
      });
    }
  },
);

export const forgotPassword = createAsyncThunk(
  `${Slice.Auth}/resetPassword`,
  async (email: any, thunkAPI) => {
    try {
      const response = await authApi.forgotPasswordRequest(email);
      return response;
    } catch (error) {
      const err = error as AxiosError<any>;
      return thunkAPI.rejectWithValue({
        error: err.response,
      });
    }
  },
);

// Two FA flows
export const setupTwoFA = createAsyncThunk(
  `${Slice.Profile}/users/2fa-setup`,
  async (_, thunkAPI) => {
    try {
      const response = await authApi.setupTWoFARequest();
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const enableTwoFA = createAsyncThunk(
  `${Slice.Profile}/users/2fa-enable`,
  async ({ deviceToken, otp }: any) => {
    try {
      const response = await authApi.enableTwoFARequest({ deviceToken, otp });
      BrowserStorageService.set(BrowserStorageKeys.AccessToken, response.data.token);
      return response;
    } catch (error) {
      const err = error as AxiosError<any>;
      return err.response;
    }
  },
);

export const disableTwoFA = createAsyncThunk(
  `${Slice.Profile}/users/2fa-disable`,
  async ({ otp }: any) => {
    try {
      const response = await authApi.disableTwoFARequest(otp);
      return response;
    } catch (error) {
      const err = error as AxiosError<any>;
      return err.response;
    }
  },
);

export const signOut = createAsyncThunk(`${Slice.Auth}/signOut`, async (_, thunkAPI) => {
  try {
    BrowserStorageService.remove(BrowserStorageKeys.AccessToken);
    BrowserStorageService.remove(BrowserStorageKeys.Role);

    BrowserStorageService.remove(BrowserStorageKeys.Role, {
      session: true,
    });
    BrowserStorageService.remove(BrowserStorageKeys.UserId, {
      session: true,
    });
  } catch (error) {
    const { message } = error as Error;

    return thunkAPI.rejectWithValue({ error: message });
  }
});

export const userInfoRequest = createAsyncThunk<any, any, { rejectValue: any }>(
  `${Slice.Users}/me`,
  async (data: any, thunkAPI) => {
    try {
      const response = await client.get('/users/me');
      if (
        response.data.role !==
          BrowserStorageService.get(BrowserStorageKeys.Role, {
            session: true,
          }) ||
        BrowserStorageService.get(BrowserStorageKeys.Role)
      ) {
        BrowserStorageService.remove(BrowserStorageKeys.Role);
        BrowserStorageService.remove(BrowserStorageKeys.Role, {
          session: true,
        });
        BrowserStorageService.set(BrowserStorageKeys.Role, response.data.role);
      }
      if (
        response.data.id !== BrowserStorageService.get(BrowserStorageKeys.UserId, { session: true })
      ) {
        BrowserStorageService.remove(BrowserStorageKeys.UserId, { session: true });
        BrowserStorageService.set(BrowserStorageKeys.UserId, response.data.id, { session: true });
      }
      return { personalInfo: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: 'Get user info request failed',
      });
    }
  },
);
