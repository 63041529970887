import moment from 'moment';

import { DatesType } from '../constants/dateRange';

// January, February, and March (Q1)
// April, May, and June (Q2)
// July, August, and September (Q3)
// October, November, and December (Q4)

const calculatePeriod = (value: any, dateFormat: string, endDate?: any): any => {
  const period = {
    dateFrom: '',
    dateTo: '',
  };

  if (typeof value === 'string' && typeof endDate === 'string') {
    return period;
  }

  const month = moment(value).month();
  const year = moment(value).year();

  if (dateFormat === DatesType.year) {
    period.dateFrom = `${year}-01-01`;
    period.dateTo = `${year}-12-31 23:59:59`;
  } else if (dateFormat === DatesType.month) {
    const d = new Date(year, month + 1, 0);
    period.dateFrom = `${year}-${month + 1}-01`;
    period.dateTo = `${moment(d).format('YYYY-MM-DD')} 23:59:59`;
  } else if (dateFormat === DatesType.quarter) {
    const d = new Date(year, month + 3, 0);
    period.dateFrom = `${year}-${month + 1}-01`;
    period.dateTo = `${moment(d).format('YYYY-MM-DD')} 23:59:59`;
  } else {
    period.dateFrom = moment(value).format('YYYY-MM-DD');
    period.dateTo = endDate
      ? `${moment(endDate).format('YYYY-MM-DD')} 23:59:59`
      : moment(value).add(1, 'days').format('YYYY-MM-DD');
  }

  return period;
};

export default calculatePeriod;
