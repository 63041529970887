import moment from 'moment';

const createChartDates = function (strDate: any = new Date()): any[] {
  const startDate = moment(strDate);

  const endDate = moment('2024-12-12');
  const betweenMonths: any[] = [];

  if (startDate < endDate) {
    const date = startDate.startOf('month');

    while (date < endDate.endOf('month')) {
      betweenMonths.push(date.format('YYYY-MM'));
      date.add(1, 'month');
    }
  }

  return betweenMonths;
};
export default createChartDates;
