import { HeadCell } from 'types';

const nestedPartnersTable: HeadCell[] = [
  {
    id: 'name',
    value: 'name',
    label: 'Full name',
  },
  {
    id: 'email',
    value: 'email',
    label: 'Email',
  },
  {
    id: 'eur_rate',
    value: 'eur_rate',
    label: 'EUR Rate',
  },
  {
    id: 'purchased',
    value: 'purchased',
    label: 'Purchased, XAB',
  },
  {
    id: 'spent',
    value: 'spent',
    label: 'Spent',
  },
  { id: 'exchange_rate', value: 'exchange_rate', label: 'Exchange rate' },
  { id: 'fee', value: 'fee', label: 'Fee' },
  {
    id: 'date',
    value: 'date',
    label: 'Date and time',
  },
];

export default nestedPartnersTable;
