import TFA_info from 'constants/TFAinfo';

import styles from '../TwoFactorAuthentication.module.scss';

function TFAoff() {
  return (
    <div className={styles.tfa_off_wrapper}>
      <div className={styles.tfa_off_wrapper__title}>How to disable 2FA:</div>
      {TFA_info.TFA_off_info.map((item: string) => (
        <div key={item} className={styles.tfa_off_wrapper__item}>
          {item}
        </div>
      ))}
    </div>
  );
}
export default TFAoff;
