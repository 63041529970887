import { HeadCell } from 'types';

const commissionsTable: HeadCell[] = [
  {
    id: 'name',
    value: 'name',
    label: 'Full name',
  },
  {
    id: 'email',
    value: 'email',
    label: 'Email',
  },
  {
    id: 'eurUsdRate',
    value: 'eurUsdRate',
    label: 'EUR Rate',
  },
  {
    id: 'purchased',
    value: 'purchased',
    label: 'Purchased, XAB',
  },
  {
    id: 'spent',
    value: 'spent',
    label: 'Spent',
  },
  {
    id: 'exchange',
    value: 'exchange',
    label: 'Exchange rate',
  },
  {
    id: 'fee',
    value: 'fee',
    label: 'Fee',
  },
  {
    id: 'commission',
    value: 'commission',
    label: ['Exclusive', 'commission'],
    isArray: true,
  },
  {
    id: 'date',
    value: 'date',
    label: 'Date and time',
  },
];

export default commissionsTable;
