import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormProvider, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import { BlockWrapper, Copyright, Toast } from 'components';
import { useAppDispatch } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { authActions } from 'store/authSlice';
import { RoutesData, StatusType } from 'types';
import { validationLoginSchema } from 'utils';

import { ERRORS_MESSAGE, ERROR_CODES } from '../../constants';

import { ILoginForm } from './types';
import LoginForm from './components/LoginForm';
import styles from './Login.module.scss';
import TwoFALogin from './components/TwoFALogin';

const defaultValues: ILoginForm = {
  email: '',
  password: '',
};

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const deviceToken = uuidv4();

  const [TFAForm, showTFAForm] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const form: UseFormReturn<ILoginForm, UseFormProps> = useForm<ILoginForm>({
    defaultValues,
    resolver: yupResolver(validationLoginSchema),
  });

  const resetForm = useCallback(() => {
    form.reset(defaultValues);
  }, [form]);

  const setFormError = () => {
    form.setError('password', {
      type: 'custom',
      message: ' ',
    });
    form.setError('email', {
      type: 'custom',
      message: ' ',
    });
  };

  const handleSignIn = async ({ email, password }: ILoginForm) => {
    const formValues = {
      deviceToken,
      email,
      password,
      rememberMe: true,
    };
    try {
      const response = await dispatch(authActions.signIn(formValues)).unwrap();
      const user = await dispatch(authActions.userInfoRequest({})).unwrap();
      const status = user?.personalInfo?.state?.status;

      if (status === StatusType.BLOCKED || status === StatusType.SUSPENDED) {
        setFormError();
        setToastMessage(ERRORS_MESSAGE.statusError(status));
        setErrorToast(true);
        BrowserStorageService.remove(BrowserStorageKeys.AccessToken);
        BrowserStorageService.remove(BrowserStorageKeys.AccessToken, { session: true });

        return;
      } else if (status === StatusType.ACTIVE) {
        if (response?.twoFactorAuthEnabled) {
          showTFAForm(true);
        } else {
          navigate(RoutesData.Dashboard);
        }
      }
    } catch (error: any) {
      const status = error?.error?.response?.status;
      if (status === ERROR_CODES[429]) {
        setToastMessage(ERRORS_MESSAGE.tooManyRequests);
        setErrorToast(true);
      } else if (status === ERROR_CODES[503]) {
        navigate(RoutesData.Maintenance, { replace: true });
        window.location.reload();
      } else {
        setToastMessage(ERRORS_MESSAGE.signInError);
        setErrorToast(true);
        setFormError();
      }
    }
  };

  useEffect(() => {
    BrowserStorageService.set(BrowserStorageKeys.DeviceToken, deviceToken);
  }, [deviceToken]);

  useEffect(() => {
    if (errorToast) {
      setTimeout(() => {
        setErrorToast(false);
        setToastMessage('');
      }, 7000);
    }
  }, [errorToast]);

  useEffect(() => {
    return () => resetForm();
  }, [resetForm]);

  const toastClassNames = classNames(styles.toaster, {
    [styles.toaster__error]: errorToast,
  });

  return (
    <>
      {TFAForm ? (
        <TwoFALogin hideTFA={showTFAForm} />
      ) : (
        <div className={styles.container}>
          <FormProvider {...form}>
            <form autoComplete='off' onSubmit={form.handleSubmit(handleSignIn)}>
              <BlockWrapper marginBottom='0'>
                <LoginForm />
              </BlockWrapper>
            </form>
          </FormProvider>
          <Copyright />
        </div>
      )}

      <Toast showToast={errorToast} message={toastMessage} toastClassNames={toastClassNames} />
    </>
  );
};

export default Login;
