import { createSlice } from '@reduxjs/toolkit';
import assign from 'lodash/assign';

import { Slice } from 'types';

import { DashboardSliceState } from './types';
import * as dashboardThunks from './thunks';

const internalInitialState: DashboardSliceState = {
  chartData: {
    data: [],
    loading: false,
    error: null,
  },
  commissionsError: null,
  commissionsLoading: false,
  eventsList: {
    data: [],
    loading: false,
    error: null,
  },
  event: {
    data: {},
    loading: false,
    error: null,
  },
  historicalCommissions: '',
  news: {
    data: {},
    loading: false,
    error: null,
  },
  newsList: {
    data: [],
    loading: false,
    error: null,
  },
  openCommissions: '',
  paidCommissions: '',
  reinvestedTokens: '',
  reinvestedTokensError: null,
  reinvestedTokensLoading: false,
  reinvestedTokensSum: '',
  reinvestedTokensSumError: null,
  reinvestedTokensSumLoading: false,
};

const dashboardSlice = createSlice({
  name: Slice.Dashboard,
  initialState: internalInitialState,
  reducers: {
    reset: () => internalInitialState,
  },
  extraReducers(builder) {
    // commission sum
    builder
      .addCase(dashboardThunks.getCommissionsSum.fulfilled, (state, { payload }) => {
        assign(state, {
          historicalCommissions: payload.historicalCommissionsSum,
          openCommissions: payload.openCommissionsSum,
          paidCommissions: payload.paidCommissionsSum,
          commissionsLoading: false,
        });
      })
      .addCase(dashboardThunks.getCommissionsSum.pending, (state) => {
        assign(state, {
          historicalCommissions: '',
          openCommissions: '',
          paidCommissions: '',
          commissionsLoading: true,
        });
      })
      .addCase(dashboardThunks.getCommissionsSum.rejected, (state, { payload }) => {
        assign(state, {
          historicalCommissions: '',
          openCommissions: '',
          paidCommissions: '',
          commissionsLoading: false,
          commissionsError: payload,
        });
      });

    builder
      .addCase(dashboardThunks.getEvent.fulfilled, (state, { payload }) => {
        assign(state.event, {
          data: payload,
          error: null,
          loading: false,
        });
      })
      .addCase(dashboardThunks.getEvent.pending, (state) => {
        assign(state.event, {
          data: {},
          error: null,
          loading: true,
        });
      })
      .addCase(dashboardThunks.getEvent.rejected, (state, { payload }) => {
        assign(state.event, {
          data: {},
          error: payload,
          loading: false,
        });
      });

    builder
      .addCase(dashboardThunks.getReinvestedTokens.fulfilled, (state, { payload }) => {
        assign(state, {
          reinvestedTokens: payload,
          reinvestedTokensError: null,
          reinvestedTokensLoading: false,
        });
      })
      .addCase(dashboardThunks.getReinvestedTokens.pending, (state) => {
        assign(state, {
          reinvestedTokens: '',
          reinvestedTokensError: null,
          reinvestedTokensLoading: true,
        });
      })
      .addCase(dashboardThunks.getReinvestedTokens.rejected, (state, { payload }) => {
        assign(state, {
          reinvestedTokens: '',
          reinvestedTokensError: payload,
          reinvestedTokensLoading: false,
        });
      });

    builder
      .addCase(dashboardThunks.getNewsList.fulfilled, (state, { payload }) => {
        assign(state.newsList, {
          data: payload,
          error: null,
          loading: false,
        });
      })
      .addCase(dashboardThunks.getNewsList.pending, (state) => {
        assign(state.newsList, {
          data: [],
          error: null,
          loading: true,
        });
      })
      .addCase(dashboardThunks.getNewsList.rejected, (state, { payload }) => {
        assign(state.newsList, {
          data: [],
          error: payload,
          loading: false,
        });
      });

    builder
      .addCase(dashboardThunks.getNews.fulfilled, (state, { payload }) => {
        assign(state.news, {
          data: payload,
          error: null,
          loading: false,
        });
      })
      .addCase(dashboardThunks.getNews.pending, (state) => {
        assign(state.news, {
          data: [],
          error: null,
          loading: true,
        });
      })
      .addCase(dashboardThunks.getNews.rejected, (state, { payload }) => {
        assign(state.news, {
          data: [],
          error: payload,
          loading: false,
        });
      });

    builder
      .addCase(dashboardThunks.getSalesChart.fulfilled, (state, { payload }) => {
        assign(state.chartData, {
          data: payload,
          error: null,
          loading: false,
        });
      })
      .addCase(dashboardThunks.getSalesChart.pending, (state) => {
        assign(state.chartData, {
          data: {},
          error: null,
          loading: true,
        });
      })
      .addCase(dashboardThunks.getSalesChart.rejected, (state, { payload }) => {
        assign(state.chartData, {
          data: {},
          error: payload,
          loading: false,
        });
      });

    // reinvested tokens sum
    builder
      .addCase(dashboardThunks.getReinvestedTokensSum.fulfilled, (state, { payload }) => {
        assign(state, {
          reinvestedTokensSum: payload,
          reinvestedTokensSumError: null,
          reinvestedTokensSumLoading: false,
        });
      })
      .addCase(dashboardThunks.getReinvestedTokensSum.pending, (state) => {
        assign(state, {
          reinvestedTokensSum: '',
          reinvestedTokensSumError: null,
          reinvestedTokensSumLoading: true,
        });
      })
      .addCase(dashboardThunks.getReinvestedTokensSum.rejected, (state, { payload }) => {
        assign(state, {
          reinvestedTokensSum: '',
          reinvestedTokensSumError: payload,
          reinvestedTokensSumLoading: false,
        });
      });

    // events
    builder
      .addCase(dashboardThunks.getEventsList.fulfilled, (state, { payload }) => {
        assign(state.eventsList, {
          data: payload,
          error: null,
          loading: false,
        });
      })
      .addCase(dashboardThunks.getEventsList.pending, (state) => {
        assign(state.eventsList, {
          data: {},
          error: null,
          loading: true,
        });
      })
      .addCase(dashboardThunks.getEventsList.rejected, (state, { payload }) => {
        assign(state.eventsList, {
          data: {},
          error: payload,
          loading: false,
        });
      });
  },
});

const { reducer, actions } = dashboardSlice;

export const dashboardActions = {
  ...actions,
  ...dashboardThunks,
};

export * as dashboardSelectors from './selectors';

export default reducer;
