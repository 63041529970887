import * as yup from 'yup';

const validationUpdatePassword = yup.object({
  password: yup.string().required('* Enter your password to complete the password update').min(8),
  confirmPassword: yup
    .string()
    .required('* Repeat password to complete password update')
    .oneOf([yup.ref('password')], '* Passwords must match'),
});

export default validationUpdatePassword;
