import styles from './Copyright.module.scss';

function Copyright() {
  return (
    <div className={styles.copyright}>
      © Copyright {new Date().getFullYear()} Teroxx Affiliate. All Rights Reserved.
    </div>
  );
}

export default Copyright;
