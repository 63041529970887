import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';

import { Event } from 'assets/icons';
import { BlockWrapper } from 'components/layouts';
import { Loader } from 'components/shared';
import { useAppDispatch, useAppSelector } from 'hooks';
import { dashboardActions, dashboardSelectors } from 'store/dashboardSlice';
import { RoutesData } from 'types';

import styles from './EventDetails.module.scss';

const displayNoneStyle = { display: 'none' };

const EventDetails = () => {
  const params = useParams();
  const { id } = params;

  const dispatch = useAppDispatch();
  const eventData = useAppSelector(dashboardSelectors.selectEventData);

  const [isLoaded, setIsLoaded] = useState(false);
  const style = isLoaded ? {} : displayNoneStyle;

  useEffect(() => {
    if (id) {
      dispatch(dashboardActions.getEvent(id));
    }
  }, [dispatch, id]);

  const imgClassName = classNames(styles.wrapper__header__img, {
    [styles.wrapper__header__img__default]: !isLoaded,
  });

  return (
    <div style={{ width: '90%' }}>
      <BlockWrapper
        backTo='Back to dashboard'
        backToPath={RoutesData.Dashboard}
        marginBottom='0px'
        navigationPath={`${RoutesData.Dashboard}/calendar`}
        navTo='View all events'
      >
        {eventData?.loading ? (
          <Loader />
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.wrapper__header}>
              <div className={imgClassName}>
                <img
                  alt={eventData?.data?.name}
                  onLoad={() => setIsLoaded(true)}
                  src={eventData?.data?.image}
                  style={style}
                />
                {isLoaded ? null : <Event />}
              </div>
              <div className={styles.wrapper__header__title_block}>
                <span className={styles.date}>
                  {moment.utc(eventData?.data?.startAt).format('D MMM YYYY')}
                </span>
                <h1 className={styles.title}>{eventData?.data?.name}</h1>
                <h2 className={styles.time}>
                  {moment.utc(eventData?.data?.startAt).format('HH:mm')} CET
                </h2>
              </div>
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: eventData?.data?.body }}
              className={styles.wrapper__content}
            ></p>
          </div>
        )}
      </BlockWrapper>
    </div>
  );
};

export default EventDetails;
