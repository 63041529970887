import { createSlice } from '@reduxjs/toolkit';
import assign from 'lodash/assign';

import { Slice } from 'types';

import { NestedPartnersSliceState } from './types';
import * as nestedPartnersThunk from './thunks';

const internalInitialState: NestedPartnersSliceState = {
  nestedPartnersData: {
    data: [],
    loading: false,
    error: null,
  },
  nestedPartnerByIdData: {
    data: {},
    loading: false,
    error: null,
  },
  nestedPartnerStatistic: {
    data: {},
    loading: false,
    error: null,
  },
};

const nestedPartnersSlice = createSlice({
  name: Slice.NestedPartners,
  initialState: internalInitialState,
  reducers: {
    reset: () => internalInitialState,
  },
  extraReducers(builder) {
    builder
      .addCase(nestedPartnersThunk.getNestedPartners.fulfilled, (state, action) => {
        assign(state.nestedPartnersData, {
          data: action.payload,
          error: null,
          loading: false,
        });
      })
      .addCase(nestedPartnersThunk.getNestedPartners.pending, (state) => {
        assign(state.nestedPartnersData, {
          data: [],
          error: null,
          loading: true,
        });
      })
      .addCase(nestedPartnersThunk.getNestedPartners.rejected, (state, action) => {
        assign(state.nestedPartnersData, {
          data: [],
          error: action.payload,
          loading: false,
        });
      });

    builder
      .addCase(nestedPartnersThunk.getNestedPartnerById.fulfilled, (state, action) => {
        assign(state.nestedPartnerByIdData, {
          data: action.payload,
          error: null,
          loading: false,
        });
      })
      .addCase(nestedPartnersThunk.getNestedPartnerById.pending, (state) => {
        assign(state.nestedPartnerByIdData, {
          data: {},
          error: null,
          loading: true,
        });
      })
      .addCase(nestedPartnersThunk.getNestedPartnerById.rejected, (state, action) => {
        assign(state.nestedPartnerByIdData, {
          data: {},
          error: action.payload,
          loading: false,
        });
      });

    builder
      .addCase(nestedPartnersThunk.getNestedPartnersStatistic.fulfilled, (state, action) => {
        assign(state.nestedPartnerStatistic, {
          data: action.payload,
          error: null,
          loading: false,
        });
      })
      .addCase(nestedPartnersThunk.getNestedPartnersStatistic.pending, (state) => {
        assign(state.nestedPartnerStatistic, {
          data: [],
          error: null,
          loading: true,
        });
      })
      .addCase(nestedPartnersThunk.getNestedPartnersStatistic.rejected, (state, action) => {
        assign(state.nestedPartnerStatistic, {
          data: [],
          error: action.payload,
          loading: false,
        });
      });
  },
});

const { reducer, actions } = nestedPartnersSlice;

export const nestedPartnersActions = {
  ...actions,
  ...nestedPartnersThunk,
};

export * as nestedPartnersSelectors from './selectors';

export default reducer;
