import BigNumber from 'bignumber.js';

import { decimals } from '../constants';

export const fiatDecimal = 10000;

// like from wei to eth, or from satoshi to btc
export function toCoin(coins: bigint | number, coin: string): BigNumber {
  const decimal = getDecimal(coin);
  return new BigNumber(coins?.toString()).div(new BigNumber(decimal));
}

export function getDecimal(coin: string): string {
  const decimal = decimals[coin] || '10000';
  if (!decimal) {
    throw new Error(`can't find decimal for currency: ${coin}`);
  }
  return decimal;
}

export function exchangeViaCoins(
  amountInCoins: string | number | BigNumber,
  rate: number,
  fromCoin: string,
  toCoin: string,
) {
  BigNumber.config({
    EXPONENTIAL_AT: 1000000000,
  });
  const conversionFrom = decimals[fromCoin];
  const conversionTo = decimals[toCoin];
  const value = new BigNumber(amountInCoins || 0).times(rate).div(new BigNumber(conversionFrom));

  return BigInt(value.times(new BigNumber(conversionTo)).dp().toString());
}

const calcsDecimals = {
  toCoin,
  getDecimal,
  exchangeViaCoins,
};

export default calcsDecimals;
