import { useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';

import { Download, Search } from 'assets/icons';
import { BlockWrapper } from 'components/layouts';
import { CommissionsTable } from 'components/views';
import { Button, DatePicker, Input } from 'components/shared';
import { useAppDispatch, useAppSelector } from 'hooks';
import { commissionsActions, commissionsSelectors } from 'store/commissionsSlice';
import { calculatePeriod } from 'utils';

import styles from './Commissions.module.scss';

const Commissions: React.FC = () => {
  const commissionsRef = useRef<ReturnType<typeof setInterval> | string>();
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [dateFrom, setDateFromValue] = useState<string | undefined>('');
  const [dateTo, setDateToValue] = useState<string | undefined>('');

  const commissionData = useAppSelector(commissionsSelectors.selectCommissionsData);
  const loading = commissionData.loading;

  const getCommissions = useCallback(
    (search?: string) => {
      dispatch(
        commissionsActions.getTransactions({
          skip,
          take,
          sort: 'createdAt',
          order: 'DESC',
          globalSearch: search,
          dateFrom,
          dateTo,
        }),
      );
    },
    [dateFrom, dateTo, dispatch, skip, take],
  );

  useEffect(() => {
    getCommissions();
  }, [getCommissions]);

  const handleSearch = (value: string) => {
    setSkip(0);
    const inputValue = value.trim();
    setSearchValue(inputValue);

    clearTimeout(commissionsRef.current);
    commissionsRef.current = setTimeout(() => getCommissions(inputValue), 1_000);
  };

  const handleDate = useCallback((startDate: any, dateFormat: string, endDate?: any) => {
    setSkip(0);
    setTake(10);
    const period = calculatePeriod(startDate, dateFormat, endDate);
    setDateFromValue(period.dateFrom);
    setDateToValue(period.dateTo);
  }, []);

  return (
    <div style={{ width: '90%' }}>
      <BlockWrapper marginBottom='20px' title='Commissions'>
        <div className={styles.wrapper}>
          <div className={styles.wrapper__header_block}>
            <div className={styles.search_block}>
              <Input
                adornment={
                  <Tooltip followCursor={true} placement='left-start' title='Search'>
                    <Search />
                  </Tooltip>
                }
                name='search_commission'
                onChange={(e: any) => handleSearch(e.target.value)}
                placeHolder='Search by email'
                value={searchValue}
                view='startAdornment'
              />
            </div>
            <div className={styles.date_range_block}>
              <DatePicker onChangeDate={handleDate} />
              <div className={styles.date_range_block__wrapper_export_btn}>
                <Button
                  title='Export to XLS'
                  Icon={Download}
                  className={styles.date_range_block__export_btn}
                  callback={async () =>
                    await dispatch(
                      commissionsActions.downloadTransactions({
                        skip: 0,
                        take,
                        sort: 'createdAt',
                        order: 'DESC',
                        dateFrom,
                        dateTo,
                      }),
                    ).unwrap()
                  }
                  disabled={loading}
                />
              </div>
            </div>
          </div>

          <div className={styles.wrapper__table_block}>
            <CommissionsTable setSkip={setSkip} setTake={setTake} skip={skip} take={take} />
          </div>
        </div>
      </BlockWrapper>
    </div>
  );
};

export default Commissions;
