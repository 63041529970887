import store from 'store';

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export enum Slice {
  Auth = 'auth',
  Commissions = 'commissions',
  Customers = 'customers',
  Dashboard = 'dashboard',
  NestedPartners = 'nestedPartners',
  Payouts = 'payouts',
  Profile = 'profile',
  Users = 'users',
}
