import { ChangeEvent } from 'react';
import TableBody from '@mui/material/TableBody';

import { EmptyData, Loader, Pagination, ScrollWrapper, Table } from 'components/shared';
import { customersTable } from 'constants/index';
import { useAppSelector } from 'hooks';
import { customersSelectors } from 'store/customersSlice';
import { ITableTypes } from 'types';

import CustomerRow from './CustomerRow';
import styles from './CustomersTable.module.scss';

const CustomersTable = ({ setSkip, setTake, skip, take }: ITableTypes) => {
  const customersData = useAppSelector(customersSelectors.selectCustomersData);
  const loading = useAppSelector(customersSelectors.selectCustomersDataLoading);
  const totalCount = customersData?.totalCount || 1;

  const handlePage = (_event: unknown, newPage: number) => {
    setSkip(newPage * take);
  };
  const handleRows = (event: ChangeEvent<HTMLInputElement>) => {
    setTake(event.target.value);
    setSkip(0);
  };

  return (
    <>
      <div>
        <h3 className={styles.table_title}>Assigned customers</h3>
        {loading ? (
          <Loader />
        ) : (
          <ScrollWrapper>
            <Table headerArray={customersTable}>
              <TableBody>
                {customersData?.list?.map((row: any) => (
                  <CustomerRow row={row} key={row.email} />
                ))}
              </TableBody>
            </Table>
          </ScrollWrapper>
        )}
        {!customersData?.totalCount && !loading && <EmptyData />}
      </div>

      <Pagination
        handleChangePage={handlePage}
        handleChangeRowsPerPage={handleRows}
        currentPage={skip / take || 0}
        rowsPerPage={take}
        totalCount={totalCount}
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
    </>
  );
};
export default CustomersTable;
