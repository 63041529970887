import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { customersApi } from 'api';
import { Slice } from 'types';

export const getCustomers = createAsyncThunk(
  `${Slice.Customers}/users`,
  async (
    credentials: {
      skip: number;
      take: number;
      sort: string;
      order: string;
      globalSearch?: string;
    },
    thunkAPI,
  ) => {
    const { ...restCredentials } = credentials;

    try {
      const response = await customersApi.getCustomersRequest(restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const getCustomersXAB = createAsyncThunk(
  `${Slice.Customers}/users/xab-transactions`,
  async (
    credentials: {
      userId: string;
      skip: number;
      take: number;
      sort: string;
      order: string;
      dateFrom?: string;
      dateTo?: string;
      filter?: any;
    },
    thunkAPI,
  ) => {
    const { userId, ...restCredentials } = credentials;
    try {
      const response = await customersApi.getCustomersXABRequest(userId, restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const getCustomersBonus = createAsyncThunk(
  `${Slice.Customers}/users/bonus-transactions`,
  async (
    credentials: {
      userId: string;
      skip: number;
      take: number;
      sort: string;
      order: string;
      dateFrom?: string;
      dateTo?: string;
      filter?: any;
    },
    thunkAPI,
  ) => {
    const { userId, ...restCredentials } = credentials;

    try {
      const response = await customersApi.getCustomersBonusRequest(userId, restCredentials);
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue({ error: '* Incorrect' });
    }
  },
);

export const setCurrentCustomer = createAction<any>('setCurrentCustomer');
